import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useSelector} from 'react-redux'
import paths from './paths'

const ProtectedRoute = () => {
  const skipStorage = ['/login', '/login-redirect']
  debugger
  if (!skipStorage.includes(window.location.pathname)) {
    var url = window.location.pathname + window.location.search
    var path = paths.questionPanel.substring(
      0,
      paths.questionPanel.lastIndexOf('/:')
    )
    if (url.includes(path)) {
      localStorage.setItem('RedirectUrl', url)
    } else {
      localStorage.removeItem('RedirectUrl')
    }
  }
  const user = useSelector(state => state.auth.user)
  return user ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoute
