import {
  Autocomplete,
  Stack,
  TextField,
  Grid,
  FormControl,
  Chip,
  Alert,
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {surveysApi, surveyTagsApi} from '../../../services'
import WbButton from '../../../components/common/WbButton'
import WbAlert from '../../../components/common/WbAlert'
import {useNavigate} from 'react-router-dom'
import paths from '../../../routes/paths'
import WbOutlineButton from '../../../components/common/WbOutlineButton'
import useCustomerData from '../../../hooks/useCustomerData'

export default function SurveySettings({
  survey,
  setSurvey,
  onPrevious,
  onCancel,
}) {
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  const customer = useCustomerData()
  const navigate = useNavigate()

  useEffect(() => {
    if (survey?.surveysTags?.length > 0)
      setSelectedTags([...survey?.surveysTags?.map(t => ({tag: t}))])
    debugger
    surveyTagsApi.get(customer.id, customer.customerType).then(response => {
      setTags([
        ...response?.map(t => {
          return {
            tag: t.tag,
          }
        }),
      ])
    })
  }, [])

  const save = () => {
    if (survey?.name === '') {
      WbAlert({message: 'Survey name is required', type: 'error'})
      return
    }
    if (!onPrevious) {
      surveysApi
        .put({
          ...survey,
          name: survey?.name,
          surveysTags: selectedTags?.map(t => t.tag),
        })
        .then(response => {
          setSurvey(response)
          window.location.href = paths.questionPanel.replace(
            ':token',
            encodeURIComponent(response.token)
          )
          WbAlert({
            message: 'Survey updated successfully',
            type: 'success',
          })
        })
    } else {
      surveysApi
        .post({
          ...survey,
          name: survey?.name,
          surveysTags: selectedTags?.map(t => t.tag),
        })
        .then(response =>
          navigate(
            paths.questionPanel.replace(
              ':token',
              encodeURIComponent(response.token)
            ),
            {state: {survey: response}}
          )
        )
        .catch(error => {})
        .finally(() => {})
    }
  }
  return (
    <Grid container p={3} spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label>Survey Name</label>
        <FormControl fullWidth>
          <TextField
            //label={'Survey Name'}
            value={survey?.name}
            onChange={event => setSurvey({...survey, name: event.target.value})}
            sx={{marginTop: '10px'}}
            className="survey-name-txt"
          ></TextField>
        </FormControl>
      </Grid>
      {!onPrevious && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <label>Tags</label>
          <Autocomplete
            sx={{marginTop: '10px'}}
            freeSolo
            multiple
            value={selectedTags}
            className="survey-name-txt"
            options={[...tags?.sort((a, b) => a.tag.localeCompare(b.tag))].map(
              t => t
            )}
            getOptionLabel={option => option.tag}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                debugger
                return (
                  <Chip
                    label={option.tag}
                    {...getTagProps({index})}
                    onDelete={s =>
                      setSelectedTags(
                        selectedTags.filter((t, i) => i !== index)
                      )
                    }
                  />
                )
              })
            }
            onChange={(event, newValue) => {
              if (selectedTags.length >= 3) {
                WbAlert({
                  message: 'You can only select up to 3 tags',
                  type: 'error',
                })
                return
              }
              let contained = false
              if (
                selectedTags.findIndex(
                  y => y.tag === newValue[newValue.length - 1].tag
                ) !== -1
              ) {
                contained = true
              }
              if (contained) {
                WbAlert({
                  message: 'You can only select unique tags',
                  type: 'error',
                })
                return
              }

              if (newValue.length > 0) {
                if (newValue[newValue.length - 1].inputValue) {
                  surveyTagsApi.post({
                    tag: newValue[newValue.length - 1].inputValue,
                    schoolId: survey.schoolId,
                    matId: survey.matId,
                  })
                  setTags([
                    ...tags,
                    {tag: newValue[newValue.length - 1].inputValue},
                  ])
                  setSelectedTags([
                    ...selectedTags,
                    {tag: newValue[newValue.length - 1].inputValue},
                  ])
                } else {
                  setSelectedTags([
                    ...selectedTags,
                    {tag: newValue[newValue.length - 1].tag},
                  ])
                }
              }
            }}
            filterOptions={(options, params) => {
              if (params.inputValue === '') return options
              const filtered = [
                ...options.filter(option =>
                  option.tag
                    .toLocaleLowerCase()
                    .includes(params.inputValue.toLocaleLowerCase())
                ),
              ]

              const {inputValue} = params
              // Suggest the creation of a new value
              const isExisting = options.some(option =>
                option.tag
                  .toLocaleLowerCase()
                  .includes(params.inputValue.toLocaleLowerCase())
              )
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue: inputValue,
                  tag: `Add "${inputValue}"`,
                })
              }

              return filtered
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderInput={params => <TextField {...params} />}
          />
          <Alert severity="info">
            Survey tags help you and our AI find and re-use surveys and
            questions in the future. Select from the existing tags or create a
            new one(s) above. You can choose up to three tags.
          </Alert>
        </Grid>
      )}
      {!onPrevious && (
        <Grid item lg={12} md={12} sm={12} xs={12} textAlign="right">
          <WbButton CustomButtonText={'Save'} onClick={save}></WbButton>
        </Grid>
      )}
      {onPrevious && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack direction="row" justifyContent="space-between" mt={6}>
            <WbOutlineButton
              CustomOutlineButtonText={'Previous'}
              onClick={onPrevious}
            ></WbOutlineButton>
            <Stack direction="row" spacing={1}>
              <WbOutlineButton
                CustomOutlineButtonText={'Cancel'}
                onClick={onCancel}
              ></WbOutlineButton>
              <WbButton
                CustomButtonText={'Create Survey'}
                onClick={save}
              ></WbButton>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}
