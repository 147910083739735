export const AUDIENCE_SELECT = [
  {value: 1, label: 'Staff'},
  {value: 2, label: 'Parent'},
  {value: 4, label: 'Student'},
]

export const SIGNTYPE = [
  {value: 1, label: 'Positive'},
  {value: 2, label: 'Negative'},
  {value: 3, label: 'Neutral'},
]

export const AUDIENCE = {
  STAFF: 1,
  PARENT: 2,
  STUDENT: 4,
}

export const CUSTOMER_TYPE = {
  MAT: 1,
  School: 2,
}

export const QUESTION_TYPE = {
  NUMBER_SLIDER: 1,
  LIKERT: 2,
  PERCENTAGE: 3,
  YES_NO: 4,
  YES_NO_MAYBE: 5,
  MULTIPLE_SELECT: 6,
  MULTIPLE_CHOICE: 7,
  RANKING: 8,
  COMMENTS: 9,
}

export const QUESTION_TYPE_SELECT = [
  {
    value: QUESTION_TYPE.NUMBER_SLIDER,
    label: 'Number Slider',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 7.5H15.5C14.625 6 13 5 11.25 5C9.5 5 7.75 6 7 7.5H0V12.5H7C7.875 14 9.5 15 11.25 15C13 15 14.75 14 15.5 12.5H20V7.5ZM1.25 11.25V8.75H6.375C6.375 9.125 6.25 9.625 6.25 10C6.25 10.375 6.375 10.875 6.375 11.25H1.25ZM11.25 13.75C9.125 13.75 7.5 12.125 7.5 10C7.5 7.875 9.125 6.25 11.25 6.25C13.375 6.25 15 7.875 15 10C15 12.125 13.375 13.75 11.25 13.75Z"
          fill="#E84F6B"
        />
      </svg>
    ),
  },
  {
    value: QUESTION_TYPE.LIKERT,
    label: 'Likert',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.25 6.875C1.25 6.0462 1.57924 5.25134 2.16529 4.66529C2.75134 4.07924 3.5462 3.75 4.375 3.75H15.625C16.4538 3.75 17.2487 4.07924 17.8347 4.66529C18.4208 5.25134 18.75 6.0462 18.75 6.875V11.875C18.75 12.2854 18.6692 12.6917 18.5121 13.0709C18.3551 13.45 18.1249 13.7945 17.8347 14.0847C17.5445 14.3749 17.2 14.6051 16.8209 14.7621C16.4417 14.9192 16.0354 15 15.625 15H4.375C3.5462 15 2.75134 14.6708 2.16529 14.0847C1.57924 13.4987 1.25 12.7038 1.25 11.875V6.875ZM4.375 5C3.87772 5 3.40081 5.19754 3.04917 5.54917C2.69754 5.90081 2.5 6.37772 2.5 6.875V11.875C2.5 12.3723 2.69754 12.8492 3.04917 13.2008C3.40081 13.5525 3.87772 13.75 4.375 13.75H15.625C16.1223 13.75 16.5992 13.5525 16.9508 13.2008C17.3025 12.8492 17.5 12.3723 17.5 11.875V6.875C17.5 6.37772 17.3025 5.90081 16.9508 5.54917C16.5992 5.19754 16.1223 5 15.625 5H4.375ZM8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H15C15.1658 8.75 15.3247 8.81585 15.4419 8.93306C15.5592 9.05027 15.625 9.20924 15.625 9.375C15.625 9.54076 15.5592 9.69973 15.4419 9.81694C15.3247 9.93415 15.1658 10 15 10H9.375C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375ZM7.5 9.375C7.5 9.87228 7.30246 10.3492 6.95083 10.7008C6.59919 11.0525 6.12228 11.25 5.625 11.25C5.12772 11.25 4.65081 11.0525 4.29917 10.7008C3.94754 10.3492 3.75 9.87228 3.75 9.375C3.75 8.87772 3.94754 8.40081 4.29917 8.04917C4.65081 7.69754 5.12772 7.5 5.625 7.5C6.12228 7.5 6.59919 7.69754 6.95083 8.04917C7.30246 8.40081 7.5 8.87772 7.5 9.375Z"
          fill="#45338C"
        />
      </svg>
    ),
  },
  {
    value: QUESTION_TYPE.PERCENTAGE,
    label: 'Percentage',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4175 2.91666L2.91748 15.4175L4.58081 17.0808L17.0808 4.58082L15.4175 2.91666ZM5.83498 4.16666C5.61611 4.16644 5.39934 4.20933 5.19705 4.29289C4.99476 4.37644 4.8109 4.49902 4.65598 4.65363C4.50106 4.80824 4.37811 4.99185 4.29415 5.19398C4.21019 5.3961 4.16687 5.61279 4.16665 5.83166C4.16643 6.05053 4.20932 6.2673 4.29288 6.46959C4.37643 6.67188 4.49901 6.85574 4.65362 7.01066C4.80823 7.16558 4.99184 7.28852 5.19397 7.37248C5.39609 7.45644 5.61278 7.49977 5.83165 7.49999C6.27368 7.50043 6.69777 7.32526 7.01065 7.01301C7.32352 6.70076 7.49954 6.27702 7.49998 5.83499C7.50042 5.39296 7.32525 4.96886 7.013 4.65599C6.70076 4.34312 6.27701 4.1671 5.83498 4.16666ZM14.1683 12.5C13.7263 12.4995 13.3022 12.6747 12.9893 12.987C12.6764 13.2992 12.5004 13.723 12.5 14.165C12.4995 14.607 12.6747 15.0311 12.987 15.344C13.2992 15.6569 13.723 15.8329 14.165 15.8333C14.3839 15.8335 14.6006 15.7906 14.8029 15.7071C15.0052 15.6235 15.1891 15.501 15.344 15.3463C15.4989 15.1917 15.6219 15.0081 15.7058 14.806C15.7898 14.6039 15.8331 14.3872 15.8333 14.1683C15.8335 13.9495 15.7906 13.7327 15.7071 13.5304C15.6235 13.3281 15.5009 13.1442 15.3463 12.9893C15.1917 12.8344 15.0081 12.7115 14.806 12.6275C14.6039 12.5435 14.3872 12.5002 14.1683 12.5Z"
          fill="#FFB205"
        />
      </svg>
    ),
  },
  {
    value: QUESTION_TYPE.YES_NO,
    label: 'Yes/No',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.81818 5.90908H18.1818C18.3024 5.90908 18.418 5.95697 18.5032 6.04221C18.5885 6.12745 18.6364 6.24307 18.6364 6.36362V13.6363C18.6364 13.7569 18.5885 13.8725 18.5032 13.9578C18.418 14.043 18.3024 14.0909 18.1818 14.0909H1.81818C1.69763 14.0909 1.58201 14.043 1.49677 13.9578C1.41153 13.8725 1.36364 13.7569 1.36364 13.6363V6.36362C1.36364 6.24307 1.41153 6.12745 1.49677 6.04221C1.58201 5.95697 1.69763 5.90908 1.81818 5.90908ZM0 6.36362C0 5.88141 0.191558 5.41895 0.532533 5.07797C0.873508 4.737 1.33597 4.54544 1.81818 4.54544H18.1818C18.664 4.54544 19.1265 4.737 19.4675 5.07797C19.8084 5.41895 20 5.88141 20 6.36362V13.6363C20 14.1186 19.8084 14.581 19.4675 14.922C19.1265 15.263 18.664 15.4545 18.1818 15.4545H1.81818C1.33597 15.4545 0.873508 15.263 0.532533 14.922C0.191558 14.581 0 14.1186 0 13.6363V6.36362ZM5.45455 9.31817C5.27372 9.31817 5.10029 9.39 4.97243 9.51787C4.84456 9.64573 4.77273 9.81916 4.77273 9.99999C4.77273 10.1808 4.84456 10.3542 4.97243 10.4821C5.10029 10.61 5.27372 10.6818 5.45455 10.6818H14.5455C14.7263 10.6818 14.8997 10.61 15.0276 10.4821C15.1554 10.3542 15.2273 10.1808 15.2273 9.99999C15.2273 9.81916 15.1554 9.64573 15.0276 9.51787C14.8997 9.39 14.7263 9.31817 14.5455 9.31817H5.45455Z"
          fill="#53B5E0"
        />
      </svg>
    ),
  },
  {
    value: QUESTION_TYPE.YES_NO_MAYBE,
    label: 'Yes/No/Maybe',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 13.3333C10.9283 13.3333 11.7161 13.01 12.3633 12.3633C13.01 11.7156 13.3333 10.9278 13.3333 10C13.3333 9.07222 13.01 8.28472 12.3633 7.6375C11.7167 6.99028 10.9289 6.66667 10 6.66667C9.07111 6.66667 8.28361 6.99028 7.6375 7.6375C6.99139 8.28472 6.66778 9.07222 6.66667 10C6.66556 10.9278 6.98917 11.7156 7.6375 12.3633C8.28583 13.0111 9.07333 13.3344 10 13.3333ZM10.0025 17.5C8.96528 17.5 7.99028 17.3033 7.0775 16.91C6.16472 16.5161 5.37056 15.9817 4.695 15.3067C4.01944 14.6317 3.48472 13.8383 3.09083 12.9267C2.69694 12.015 2.5 11.0403 2.5 10.0025C2.5 8.96472 2.69694 7.98972 3.09083 7.0775C3.48417 6.16472 4.01778 5.37056 4.69167 4.695C5.36556 4.01944 6.15917 3.48472 7.0725 3.09083C7.98583 2.69694 8.96083 2.5 9.9975 2.5C11.0342 2.5 12.0092 2.69694 12.9225 3.09083C13.8353 3.48417 14.6294 4.01806 15.305 4.6925C15.9806 5.36694 16.5153 6.16056 16.9092 7.07333C17.3031 7.98611 17.5 8.96083 17.5 9.9975C17.5 11.0342 17.3033 12.0092 16.91 12.9225C16.5167 13.8358 15.9822 14.63 15.3067 15.305C14.6311 15.98 13.8378 16.5147 12.9267 16.9092C12.0156 17.3036 11.0408 17.5006 10.0025 17.5Z"
          fill="#E84F6B"
        />
      </svg>
    ),
  },
  {
    value: QUESTION_TYPE.MULTIPLE_SELECT,
    label: 'Multiple Select',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33333 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5H3.33333C3.11232 17.5 2.90036 17.4122 2.74408 17.2559C2.5878 17.0996 2.5 16.8877 2.5 16.6667V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5ZM9.16917 13.3333L15.0608 7.44083L13.8833 6.2625L9.16917 10.9767L6.81167 8.61917L5.63333 9.7975L9.16917 13.3333Z"
          fill="#007A7A"
        />
      </svg>
    ),
  },
  {
    value: QUESTION_TYPE.MULTIPLE_CHOICE,
    label: 'Multiple Choice',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM10 16.875C8.64026 16.875 7.31105 16.4718 6.18046 15.7164C5.04987 14.9609 4.16868 13.8872 3.64833 12.6309C3.12798 11.3747 2.99183 9.99237 3.2571 8.65875C3.52238 7.32513 4.17716 6.10013 5.13864 5.13864C6.10013 4.17716 7.32514 3.52237 8.65876 3.2571C9.99238 2.99183 11.3747 3.12798 12.631 3.64833C13.8872 4.16868 14.9609 5.04987 15.7164 6.18045C16.4718 7.31104 16.875 8.64025 16.875 10C16.8729 11.8227 16.1479 13.5702 14.8591 14.8591C13.5702 16.1479 11.8227 16.8729 10 16.875ZM14.375 10C14.375 10.8653 14.1184 11.7112 13.6377 12.4306C13.157 13.1501 12.4737 13.7108 11.6742 14.042C10.8748 14.3731 9.99515 14.4597 9.14648 14.2909C8.29782 14.1221 7.51827 13.7054 6.90641 13.0936C6.29456 12.4817 5.87788 11.7022 5.70907 10.8535C5.54026 10.0049 5.6269 9.12519 5.95803 8.32576C6.28916 7.52633 6.84992 6.84305 7.56938 6.36232C8.28885 5.88159 9.13471 5.625 10 5.625C11.1599 5.62624 12.272 6.08758 13.0922 6.90778C13.9124 7.72798 14.3738 8.84006 14.375 10Z"
          fill="#883C05"
        />
      </svg>
    ),
  },
  // {
  //   value: QUESTION_TYPE.RANKING,
  //   label: 'Ranking',
  //   image: (
  //     <svg
  //       width="20"
  //       height="20"
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         fill-rule="evenodd"
  //         clip-rule="evenodd"
  //         d="M0.833496 7.50002C0.833496 7.03978 1.20659 6.66669 1.66683 6.66669H7.0835C7.54373 6.66669 7.91683 7.03978 7.91683 7.50002V17.5C7.91683 17.9603 7.54373 18.3334 7.0835 18.3334H1.66683C1.20659 18.3334 0.833496 17.9603 0.833496 17.5V7.50002ZM2.50016 8.33335V16.6667H6.25016V8.33335H2.50016Z"
  //         fill="#FF5939"
  //       />
  //       <path
  //         fill-rule="evenodd"
  //         clip-rule="evenodd"
  //         d="M6.25 2.50002C6.25 2.03978 6.6231 1.66669 7.08333 1.66669H12.5C12.9602 1.66669 13.3333 2.03978 13.3333 2.50002V17.5C13.3333 17.9603 12.9602 18.3334 12.5 18.3334H7.08333C6.6231 18.3334 6.25 17.9603 6.25 17.5V2.50002ZM7.91667 3.33335V16.6667H11.6667V3.33335H7.91667Z"
  //         fill="#FF5939"
  //       />
  //       <path
  //         fill-rule="evenodd"
  //         clip-rule="evenodd"
  //         d="M11.667 10.8333C11.667 10.3731 12.0401 10 12.5003 10H17.917C18.3772 10 18.7503 10.3731 18.7503 10.8333V17.5C18.7503 17.9602 18.3772 18.3333 17.917 18.3333H12.5003C12.0401 18.3333 11.667 17.9602 11.667 17.5V10.8333ZM13.3337 11.6667V16.6667H17.0837V11.6667H13.3337Z"
  //         fill="#FF5939"
  //       />
  //     </svg>
  //   ),
  // },
  {
    value: QUESTION_TYPE.COMMENTS,
    label: 'Comments',
    image: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 5V6.66667H2.5V5H17.5ZM2.5 15H10V13.3333H2.5V15ZM2.5 10.8333H17.5V9.16667H2.5V10.8333Z"
          fill="#6D6D6D"
        />
      </svg>
    ),
  },
]

export const SURVEY_CYCLE_TYPE = {
  ONE_OFF: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  BI_MONTHLY: 4,
  QUARTERLY: 5,
  CUSTOM: 6,
}

export const SURVEY_CYCLES = {
  [SURVEY_CYCLE_TYPE.ONE_OFF]: 'One-off',
  [SURVEY_CYCLE_TYPE.WEEKLY]: 'Weekly',
  [SURVEY_CYCLE_TYPE.MONTHLY]: 'Monthly',
  [SURVEY_CYCLE_TYPE.BI_MONTHLY]: 'Bi-Monthly',
  [SURVEY_CYCLE_TYPE.QUARTERLY]: 'Quarterly',
  [SURVEY_CYCLE_TYPE.CUSTOM]: 'Custom',
}

export const Roles = {
  SUPER_ADMIN: 'SuperAdmin',
  HEAD_TEACHER: 'HeadTeacher',
  GROUP_DIRECTOR: 'GroupDirector',
}

export const CARD_COLORS = [
  '#45338C',
  '#E84F6B',
  '#007A7A',
  '#2A9BD9',
  '#EB5B41',
  '#45338C',
  '#E84F6B',
  '#007A7A',
]

export const DEFAULT_EMAIL_TEMPLATE = {
  subject: 'Welbee custom survey: your feedback is important',
  message:
    '<p>Dear {personFirstName},</p>\r\n   ' +
    "<p>Please complete the short survey.</p><p>Your feedback is important to us and please make sure you have your say and don't rely on others, who may have different views.</p>" +
    "<p><br><a href='{surveyLink}' style='color: #53B5E0; font-weight: 900;'>Please click here to begin.</a></p><p><br><strong>Welbee (on behalf of {schoolName})</strong><br/> ",
}
