import React, {useState} from 'react'
import SurveyType from '../../components/screens/surveyForms/SurveyType'
import SurveyDetails from '../../components/screens/surveyForms/SurveyDetails'
import WbModal from '../../components/common/WbModal'
import SurveyTemplates from '../SurveyTemplates'
import {surveysApi} from '../../services'
import {Dialog, DialogContent, Stack} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import paths from '../../routes/paths'
import SurveyName from '../../components/screens/surveyForms/SurveyName'
import SurveySettings from './QuestionPanel/SurveySettings'

export default function ({onClose}) {
  const navigate = useNavigate()

  const [loadTemplates, setLoadtemplates] = useState(false)
  const [survey, setSurvey] = useState(null)
  const [screen, setScreen] = useState(1)

  const handleCreateOrLoadTemplates = type => {
    if (type === 'scratch') {
      setScreen(3)
      // surveysApi
      //   .post(survey)
      //   .then(response =>
      //     navigate(
      //       paths.questionPanel.replace(
      //         ':token',
      //         encodeURIComponent(response.token)
      //       ),
      //       {state: {survey: response}}
      //     )
      //   )
      //   .catch(error => {})
      //   .finally(() => {})
    } else {
      //setLoadtemplates(true)
      navigate(
        paths.surveyTemplatesPanel
          .replace(':audience', survey.audience)
          .replace(':anonimous', survey.anonymous)
          .replace(':shared', survey.sharedResults)
      )
    }
  }

  return (
    <WbModal
      onClose={onClose}
      title={'Create new survey'}
      subtitle={
        screen === 1
          ? 'Select the audience for this survey. You can select more than one.'
          : screen === 2
            ? 'Choose whether to use a template or start from scratch.'
            : 'Choose a name for you survey. You can change this later.'
      }
      content={
        <Stack>
          {screen === 1 ? (
            <SurveyDetails
              onComplete={s => {
                setSurvey(s)
                setScreen(2)
              }}
              onCancel={onClose}
              survey={survey}
            ></SurveyDetails>
          ) : screen === 2 ? (
            <SurveyType
              onTemplateSelect={handleCreateOrLoadTemplates}
              onCancel={onClose}
              onPrevious={() => setScreen(1)}
            ></SurveyType>
          ) : (
            // <SurveyName
            //   onCancel={onClose}
            //   survey={survey}
            //   setSurvey={setSurvey}
            //   onPrevious={() => setScreen(2)}
            //   onComplete={createSurveyFromScratch}
            // />
            <SurveySettings
              survey={survey}
              setSurvey={setSurvey}
              onPrevious={() => setScreen(2)}
              onCancel={onClose}
            />
          )}
        </Stack>
      }
    />
  )
}
