import request from '../../request'
import {emailTemplates} from '../../endpoints'

export default class emailTemplateApi {
  static async get(schoolId, matId, audience, surveyId) {
    return request({
      url: emailTemplates.get(schoolId, matId, audience, surveyId),
      method: 'GET',
    })
  }
  static async post(data) {
    return request({
      url: emailTemplates.post(),
      method: 'POST',
      data: data,
    })
  }
  static async put(data) {
    return request({
      url: emailTemplates.put(),
      method: 'PUT',
      data: data,
    })
  }
  static async delete(id) {
    return request({
      url: emailTemplates.delete(id),
      method: 'DELETE',
    })
  }
}
