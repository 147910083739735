import {AppBar, IconButton} from '@mui/material'
import React from 'react'
import {Grid, Container, Stack} from '@mui/material'
import PupilSurveyCard from './PupilSurveyCard'
import {Logout} from '@mui/icons-material'

export default function PupilDashboard({surveys}) {
  return (
    <>
      <AppBar className="header">
        <Container maxWidth="xxl">          
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div className="logo">
              <span>Welbee</span>
            </div>
            <div>
              <nav>
                <IconButton
                  onClick={() => {
                    localStorage.removeItem('pupil')
                    window.location.href = '/pupil-surveys/auth'
                  }}
                >
                  <Logout sx={{color: '#fff', fontSize: 28}}/>
                </IconButton>
              </nav>
            </div>
          </Stack>
        </Container>
      </AppBar>
      <div className="main-container">
        <Container maxWidth="lg">
          <Grid container spacing={2} mt={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>All Surveys</h1>
            </Grid>
            {surveys?.length === 0 && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div>No surveys available</div>
              </Grid>
            )}

            {surveys.map((survey, index) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                <PupilSurveyCard survey={survey} index={index} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  )
}
