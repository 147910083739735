import request from '../../request'
import {tags} from '../../endpoints'

export default class tagsApi {
  static async get() {
    return request({
      url: tags.get(),
      method: 'GET',
    })
  }
  static async getTagsByAudience(audience, s, m) {
    return request({
      url: tags.getTagsByAudience(audience, s, m),
      method: 'GET',
    })
  }
  static async tagQuestions(data) {
    return request({
      url: tags.tagQuestions(),
      method: 'POST',
      data: JSON.stringify(data),
    })
  }
}
