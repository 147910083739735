import React, {useEffect} from 'react'
import WbModal from '../../../components/common/WbModal'
import tagsApi from '../../../services/api/questionLibrary/tagsApi'
import {AccordionDetails, Stack} from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import CategoryQuestions from '../../../components/screens/surveyForms/questions/QuestionLibrary/CategoryQuestions'

export default function QuestionLibrary({survey, questions, setQuestions}) {
  const [selectedTag, setSelectedTag] = React.useState(null)

  const [tags, setTags] = React.useState([])

  useEffect(() => {
    tagsApi
      .getTagsByAudience(survey.audience, survey.schoolId, survey.matId)
      .then(res => {
        setTags([...res.sort((a, b) => a.name.localeCompare(b.name))])
      })
  }, [])

  return (
    <>
      {tags.map(tag => (
        <div
          className="questions-library"
          style={{cursor: 'pointer'}}
          onClick={e => setSelectedTag(tag)}
        >
          <AccordionDetails>
            <Stack
              direction={'row'}
              justifyContent="space-between"
              alignItems="center"
              className="question-bank-list"
            >
              <div className="question-txt">{tag.name}</div>
              <div>
                <ArrowForwardIosSharpIcon sx={{fontSize: '12px'}} />
              </div>
            </Stack>
          </AccordionDetails>
        </div>
      ))}

      {selectedTag && (
        <WbModal
          width={'80%'}
          onClose={() => setSelectedTag(null)}
          title={selectedTag.name}
          content={
            <CategoryQuestions
              survey={survey}
              surveyQuestions={questions}
              tag={selectedTag}
              setSurveyQuestions={setQuestions}
              onClose={() => setSelectedTag(null)}
            />
          }
        ></WbModal>
      )}
    </>
  )
}
