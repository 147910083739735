import React from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import WbButton from '../../../components/common/WbButton'
import WbModal from '../../../components/common/WbModal'
import {useState} from 'react'
import WbTextField from '../../../components/common/WbTextField'
import {useEffect} from 'react'
import {CopyAll, Delete, Edit} from '@mui/icons-material'
import WbOutlineButton from '../../../components/common/WbOutlineButton'
import WbAlert from '../../../components/common/WbAlert'
import emailTemplateApi from '../../../services/api/emailTemplate/emailTemplateApi'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {useRef} from 'react'
import {DEFAULT_EMAIL_TEMPLATE} from '../../../utils/constants'
import useCustomerData from '../../../hooks/useCustomerData'

export default function CustomisedInvitations({
  utiliseTemplate,
  audience,
  survey,
  setSurvey,
}) {
  const getTemplateName = () => {
    return (
      new Date().toLocaleDateString().split('T')[0].replace(/-/g, '/') +
      '-' +
      (audience === 1 ? 'Staff' : audience === 2 ? 'Parent' : 'Student')
    )
  }

  const [showCustomisedInvitations, setShowCustomisedInvitations] =
    useState(false)
  const [getLinkText, setGetLinkText] = useState(false)
  const [selectionIndex, setSelectionIndex] = useState(0)
  const customer = useCustomerData()
  const [selected, setSelected] = useState(null)
  const [templates, setTemplates] = useState([])
  const [addNew, setAddNew] = useState(false)
  const [edit, setEdit] = useState(false)
  const [clone, setClone] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [value, setValue] = useState('')
  const quillRef = useRef(null)
  const [newTemplate, setNewTemplate] = useState({
    id: 0,
    name: getTemplateName(),
    subject: '',
    message: '',
    audience: audience,
    matId: survey?.matId,
    schoolId: survey?.schoolId,
  })

  const handleLinkClick = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor()
      const selection = quill.getSelection()
      if (!selection || selection.length === 0) {
        setGetLinkText(true)
        setSelectionIndex(selection.index)
        return
      }
      let selectedText = null
      if (selection) {
        selectedText = quill.getText(selection.index, selection.length)
      }
      if (selectedText) {
        quill.formatText(
          selection.index,
          selection.length,
          'link',
          '{surveyLink}'
        )
      }
    }
  }

  const handlePersonNameClick = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor()

      const selection = quill.getSelection()
      quill.insertText(
        selection ? selection?.index : quill.getLength(),
        '{personFirstName}'
      )
    }
  }

  useEffect(() => {
    if (survey === null) return
    emailTemplateApi
      .get(survey?.schoolId, survey?.matId, audience, survey.id)
      .then(e => {
        debugger
        setTemplates([...e])

        if (survey?.staffEmailTemplateId > 0 && audience === 1) {
          setSelected(e[e.findIndex(e => e.id === survey.staffEmailTemplateId)])
          setNewTemplate(
            e[e.findIndex(e => e.id === survey.staffEmailTemplateId)]
          )
        } else if (survey?.parentEmailTemplateId > 0 && audience === 2) {
          setSelected(e.findIndex(e => e.id === survey.parentEmailTemplateId))
          setNewTemplate(
            e.findIndex(e => e.id === survey.parentEmailTemplateId)
          )
        } else if (survey?.pupilEmailTemplateId > 0 && audience === 4) {
          setSelected(e.findIndex(e => e.id === survey.pupilEmailTemplateId))
          setNewTemplate(e.findIndex(e => e.id === survey.pupilEmailTemplateId))
        } else {
          setSelected(e[0])
          setNewTemplate(e[0])
        }
      })
  }, [])

  const updateAfterSave = e => {
    WbAlert({
      message: 'Template saved',
      type: 'success',
    })
    debugger
    setClone(false)
    setAddNew(false)
    setEdit(false)
    let newList = [...templates, e]
    setTemplates(newList)
    setSelected(newList[newList.length - 1])
    setNewTemplate(newList[newList.length - 1])
  }
  const [linkText, setLinkText] = useState('')
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'right'}}>
        <WbButton
          CustomButtonText={'Customise Invitations'}
          onClick={() => {
            setShowCustomisedInvitations(true)
          }}
        />
      </Grid>

      {getLinkText && (
        <WbModal
          onClose={() => {
            setGetLinkText(false)
          }}
          title={'Survey Link'}
          content={
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <WbTextField
                  label="Enter text to appear on survey link here..."
                  value={linkText}
                  onChange={e => setLinkText(e.target.value)}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} textAlign="right">
                <WbOutlineButton
                  CustomOutlineButtonText={'Cancel'}
                  onClick={e => setGetLinkText(false)}
                />
                {'  '}
                <WbButton
                  CustomButtonText={'Insert'}
                  onClick={e => {
                    setGetLinkText(false)

                    if (quillRef.current) {
                      const quill = quillRef.current.getEditor()
                      const selection = quill.getSelection()

                      quill.insertText(
                        selectionIndex ? selectionIndex : quill.getLength(),
                        linkText,
                        'link',
                        '{surveyLink}'
                      )
                      setLinkText('')
                      setSelectionIndex(0)
                    }
                  }}
                />
              </Grid>
            </Grid>
          }
        ></WbModal>
      )}
      {showCustomisedInvitations && (
        <WbModal
          onClose={() => {
            setAddNew(false)
            setShowCustomisedInvitations(false)
            setEdit(false)
          }}
          title={'Invitation Template'}
          maxWidth={'md'}
          content={
            <>
              <Grid container spacing={1} mt={3}>
                <Grid item lg={10} md={10} sm={9} xs={8}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="template-select-id">
                      Select Template
                    </InputLabel> */}
                    <WbTextField
                      fullWidth
                      select
                      labelId="template-select-id"
                      id="template-select"
                      label="Select Template"
                      value={selected}
                      renderValue={selected => {
                        return selected?.subject
                      }}
                      onChange={e => {
                        var sel = templates.findIndex(
                          x => x.id === e.target.value.id
                        )

                        setSelected(templates[sel])
                        setNewTemplate(templates[sel])
                        setAddNew(false)
                        setEdit(false)
                        setClone(false)
                      }}
                    >
                      {templates.map(option => (
                        <MenuItem key={option?.id} value={option}>
                          {option.subject}
                        </MenuItem>
                      ))}
                    </WbTextField>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={2}
                  sm={3}
                  xs={4}                                               
                >
                  <WbOutlineButton
                    fullWidth
                    CustomOutlineButtonText={'Add new'}
                    style={{height: '100%'}}                                        
                    onClick={() => {
                      setGetLinkText(false)
                      setAddNew(true)
                      setEdit(false)
                      setClone(false)
                      setNewTemplate({
                        id: 0,
                        name: getTemplateName(),
                        subject: DEFAULT_EMAIL_TEMPLATE.subject,
                        message: DEFAULT_EMAIL_TEMPLATE.message.replace(
                          '{schoolName}',
                          customer?.name
                        ),
                        audience: audience,
                        matId: survey?.matId,
                        schoolId: survey?.schoolId,
                      })
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    title="Copy template to new"
                    disabled={addNew}
                    onClick={() => {
                      setGetLinkText(false)
                      setNewTemplate({...selected, id: 0, audience: audience})
                      setClone(true)
                      setAddNew(false)
                      setEdit(false)
                    }}
                    disableElevation
                    disableRipple
                  >
                    <CopyAll />
                  </Button>{' '}
                  <Button
                    variant="contained"
                    color="primary"
                    title="Edit selected template"
                    disabled={addNew || selected?.id === -1}
                    onClick={() => {
                      setGetLinkText(false)
                      setAddNew(false)
                      setEdit(true)
                      setClone(false)
                      setNewTemplate({...selected})
                    }}
                    disableElevation
                    disableRipple
                  >
                    <Edit />
                  </Button>{' '}
                  <Button
                    variant="contained"
                    color="primary"
                    title="Delete selected template"
                    disabled={addNew || selected?.id === -1}
                    onClick={() => {
                      emailTemplateApi
                        .delete(selected.id)
                        .then(e => setConfirmDelete(true))
                    }}
                    disableElevation
                    disableRipple
                  >
                    <Delete />
                  </Button>
                  {confirmDelete && (
                    <WbModal
                      onClose={() => setConfirmDelete(false)}
                      title={'Confirm Delete'}
                      maxWidth={'sm'}
                      content={
                        <Stack spacing={2}>
                          <Stack spacing={2}>
                            <p>Are you sure you want to delete this template?</p>
                          </Stack>
                          <Stack
                            spacing={2}
                            direction="row"
                            style={{float: 'right'}}
                          >
                            <WbOutlineButton
                              CustomOutlineButtonText={'Cancel'}
                              onClick={() => setConfirmDelete(false)}
                            />
                            <WbButton
                              CustomButtonText={'Delete'}
                              onClick={() => {
                                setAddNew(false)
                                setEdit(false)
                                setClone(false)
                                setConfirmDelete(false)
                                setNewTemplate({
                                  id: 0,
                                  name: getTemplateName(),
                                  subject: '',
                                  message: '',
                                  audience: audience,
                                  matId: survey?.matId,
                                  schoolId: survey?.schoolId,
                                })

                                WbAlert({
                                  message: 'Template deleted',
                                  type: 'success',
                                })
                                setTemplates(
                                  templates.filter(e => e.id !== selected?.id)
                                )
                                setSelected(templates[0])
                              }}
                            />
                          </Stack>
                        </Stack>
                      }
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12} textAlign={'right'}>
                  {(addNew || edit || clone) && (
                    <>
                      {' '}
                      <WbOutlineButton
                        CustomOutlineButtonText={'Insert participant name'}
                        onClick={handlePersonNameClick}
                      />{' '}
                      <WbOutlineButton
                        CustomOutlineButtonText={'Insert survey link'}
                        onClick={handleLinkClick}
                      />
                    </>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} spacing={1}>
                  {addNew || edit || clone ? (
                    <WbTextField
                      label="Subject"
                      value={newTemplate?.subject}
                      onChange={e => {
                        setNewTemplate({...newTemplate, subject: e.target.value})
                      }}
                    ></WbTextField>
                  ) : (
                    <div>Email Subject: {selected?.subject}</div>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {addNew || edit || clone ? (
                    // <WbTextField
                    //   multiline
                    //   rows={6}
                    //   label={'Message'}
                    //   value={newTemplate.message}
                    //   onChange={e =>
                    //     setNewTemplate({
                    //       ...newTemplate,
                    //       message: e.target.value,
                    //     })
                    //   }
                    // ></WbTextField>
                    <Grid container spacing={1}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <ReactQuill
                          theme="snow"
                          ref={quillRef}
                          modules={{
                            toolbar: [
                              [{header: [1, 2, false]}],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{list: 'ordered'}, {list: 'bullet'}],
                            ],
                          }}
                          value={newTemplate.message}
                          placeholder="Message"
                          onChange={e => {
                            setNewTemplate({...newTemplate, message: e})
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selected?.message?.replace(
                          '{schoolName}',
                          customer?.name
                        ),
                      }}
                    ></div>
                  )}{' '}
                  {/* <>
                    {' '}
                    <p>
                      <br />
                      <p style={{color: ' #53B5E0', fontWeight: 900}}>
                        Please click here to begin.
                      </p>
                    </p>
                    <p>
                      <br />{' '}
                      <strong>
                        Welbee (on behalf of {'{'}schoolName{'}'})
                      </strong>
                      <br />
                    </p>
                  </> */}
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <WbOutlineButton
                    CustomOutlineButtonText={'Cancel'}
                    onClick={() => {
                      setShowCustomisedInvitations(false)
                      setAddNew(false)
                      setEdit(false)
                      setClone(false)
                      setNewTemplate({
                        id: 0,
                        name: '',
                        subject: '',
                        message: '',
                      })
                      //   utiliseTemplate(selected)
                      setSelected(null)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{textAlign: 'right'}}
                >
                  {addNew || clone || edit ? (
                    <WbOutlineButton
                      CustomOutlineButtonText={'Save'}
                      onClick={() => {
                        setGetLinkText(false)
                        if (
                          newTemplate.subject === null ||
                          newTemplate.subject === ''
                        ) {
                          WbAlert({
                            message: 'Please enter a subject',
                            type: 'error',
                          })
                          return
                        }
                        if (
                          newTemplate.message === null ||
                          newTemplate.message === ''
                        ) {
                          WbAlert({
                            message: 'Please enter a message',
                            type: 'error',
                          })
                          return
                        }

                        if (newTemplate.message.indexOf('{surveyLink}') === -1) {
                          WbAlert({
                            message: 'Please insert a survey link',
                            type: 'error',
                          })
                          return
                        }
                        if (newTemplate.id === 0) {
                          emailTemplateApi
                            .post(newTemplate)
                            .then(e => updateAfterSave(e))
                        }
                        if (newTemplate.id > 0) {
                          emailTemplateApi.put(newTemplate).then(e => {
                            WbAlert({message: 'Template saved', type: 'success'})
                            setTemplates([
                              ...templates.filter(x => x.id !== newTemplate.id),
                              newTemplate,
                            ])
                            setSelected({...newTemplate})
                          })
                        }
                      }}
                    />
                  ) : null}{' '}
                  <WbButton
                    CustomButtonText={'Use Template'}
                    onClick={e => {
                      setGetLinkText(false)
                      setShowCustomisedInvitations(false)
                      setAddNew(false)
                      setEdit(false)
                      setClone(false)
                      setNewTemplate({
                        id: 0,
                        name: getTemplateName(),
                        subject: '',
                        message: '',
                      })

                      var newSurvey = {...survey}
                      if (audience === 1) {
                        newSurvey.staffEmailTemplateId = newTemplate.id
                      }
                      if (audience === 2) {
                        newSurvey.parentEmailTemplateId = newTemplate.id
                      }
                      if (audience === 4) {
                        newSurvey.pupilEmailTemplateId = newTemplate.id
                      }
                      setSurvey({...newSurvey})
                      utiliseTemplate(newTemplate)

                      //setSelected({...defaultTemp})
                    }}
                  />
                </Grid>
              </Grid>
            </>
          }
        />
      )}
    </Grid>
  )
}
