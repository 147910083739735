import React, {useEffect} from 'react'
import EmojiButton from './EmojiButton'
import {emojis} from '../../../../utils/emojis'
import {Grid, Container, Stack} from '@mui/material'
import SelectedEmoji from './SelectedEmoji'
import WbButton from '../../../../components/common/WbButton'
import {participantsApi} from '../../../../services'
import WbAlert from '../../../../components/common/WbAlert'
import paths from '../../../../routes/paths'
import arrowPosition from '../../../../components/assets/images/arrow-position.svg'

export default function EmojiLogin() {
  const [code, setcode] = React.useState([null, null, null, null, null, null])
  const [position, setposition] = React.useState(0)

  useEffect(() => {
    localStorage.removeItem('pupil')
  }, [])
  const handleEmojiClick = value => {
    if (position >= 6) {
      return
    }
    const newCode = [...code]
    newCode[position] = value
    setcode(newCode)
    setposition(position + 1)
  }
  const handleEmojiRemove = () => {
    if (position <= 0) {
      return
    }
    const newCode = [...code]
    newCode[position - 1] = null
    setcode(newCode)
    if (position > 0) setposition(position - 1)
  }

  return (
    <div className="emoji-login">
      <Container maxWidth="md">
        <Grid container spacing={4} onKeyDown={e => e.key === 'Backspace' && handleEmojiRemove()}>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <Stack columnGap={1} direction="row" justifyContent="center" alignItems="center">
              {code.map((value, index) => ( 
                  <>
                    {' '}
                    <SelectedEmoji value={value} key={index} />    
                  </>               
              ))}        
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
            <h2>Please click the emoji that match the ones given to you by your teacher</h2>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} >
            <Stack columnGap={1} rowGap={1} direction="row" flexWrap="wrap" justifyContent="center" alignItems="center">
              {emojis.map((emoji, index) => (        
                <div key={index}>
                  <EmojiButton
                    icon={emoji.icon}
                    value={emoji.value}
                    handleClick={handleEmojiClick}
                  />
                </div>        
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <div className="start-button">
              <WbButton
                CustomButtonText={'Start'}
                disabled={code.filter(x => x === null).length > 0}
                onClick={() => {
                  participantsApi.checkPasscode(code.join('')).then(res => {
                    if (res && res.studentId > 0) {
                      localStorage.setItem('pupil', JSON.stringify(res))
                      window.location.href = paths.pupilSurveys
                    } else {
                      setcode([null, null, null, null, null, null])
                      setposition(0)
                      WbAlert({message: 'Invalid passcode', type: 'error'})
                    }
                  })
                }}
              />
              <img src={arrowPosition} alt="arrow"/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
