import {
  Grid,
  Stack,
  Button,
  CircularProgress,
  InputAdornment,
} from '@mui/material'
import WbTextField from '../../components/common/WbTextField'
import React, {useState, useEffect} from 'react'
import {RssFeed, Visibility, VisibilityOff} from '@mui/icons-material'
import axios from 'axios'
import {CUSTOMER_TYPE, Roles} from '../../utils/constants'
import {json, useNavigate} from 'react-router-dom'
import WbAlert from '../../components/common/WbAlert'
import welbeelogo from '../../components/assets/images/welbee_logo.svg'
import {parseJwt} from '../../utils/utilities'
import {useSelector} from 'react-redux'
import {signinRedirect} from '../../services/api/user/userApi'
import {Config} from '../../utils/Config'

export default function Login() {
  let [email, setEmail] = useState('')
  let [password, setPwd] = useState('')
  let [showPassword, setShowPassword] = useState(false)
  let [lockScreen, setLockScreen] = useState(false)
  let [err, setErr] = useState('')
  let [pErr, setPErr] = useState('')

  const navigate = useNavigate()

  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if (!user) {
      signinRedirect()
    }

    if (user != null) {
      onLogin(user)
    }
  }, [])

  const onLogin = user => {
    axios({
      method: 'post',
      url: Config.ADMIN_BASE_URL + '/account/login',
      headers: {'Content-Type': 'application/json'},
      data: JSON.stringify(user),
    })
      .then(res => {
        if (res.data.success === false) {
          setLockScreen(false)
          WbAlert({message: res.data.message, type: 'error'})
          return
        }
        const userInfo = res.data.data.userInfo
        let customerType =
          userInfo.Role === Roles.GROUP_DIRECTOR
            ? CUSTOMER_TYPE.MAT
            : CUSTOMER_TYPE.School

        let customer = {
          id:
            userInfo.Role === Roles.GROUP_DIRECTOR
              ? res.data.data.MatGroupId
              : res.data.data.schoolId,
          name: res.data.data.schoolName,
          customerType: customerType,
        }

        let user = {
          name: 'Insights' + ' ' + 'User',
          isMat:
            res.data.data.MatGroupId == 0 || res.data.data.MatGroupId === null
              ? false
              : true,
          email: res.data.data.userInfo.Email,
        }

        localStorage.setItem('token', res.data.data.access_token)
        localStorage.setItem('customer', JSON.stringify(customer))
        localStorage.setItem('user', JSON.stringify(user))

        setLockScreen(false)
        const url = localStorage.getItem('RedirectUrl')
        if (url) {
          navigate(url)
        } else navigate('/')
      })
      .catch(err => {
        console.log(err)
        setLockScreen(false)
      })
  }

  return (
    <>
      <div className="loading">
        <div className="login-logo">
          <img src={welbeelogo} alt="" />
        </div>
        <div>
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{'svg circle': {stroke: 'url(#my_gradient)'}}}
            size={50}
          />
        </div>
      </div>
      {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={5} md={5} display={{xs: 'none', lg: 'block'}}>
          <div className="login-main">
            <h1>
              Welcome to <span>Welbee Insights</span>
            </h1>
            <p>Bringing all your stakeholder feedback together in one place.</p>
            <div className="login-symbol">
              <img src={iconlogin} alt="" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <div className="login-container">
            <div className="login-logo">
              <img
                alt=""
                src="https://welbee.international/wp-content/uploads/2021/04/welbee_logo_yellow.png"
              ></img>
            </div>
            <div className="body-container">
              <Stack spacing={2} className="login">
                <h1>Welcome Back</h1>
                <p>Please enter your login details</p>

                <WbTextField
                  fullWidth
                  placeholder="Email"
                  label="Email"
                  value={email}
                  onChange={e => {
                    setErr('')
                    setEmail(e.target.value)
                  }}
                ></WbTextField>
                {err && <div style={{color: 'red'}}>{err}</div>}

                <WbTextField
                  fullWidth
                  label="Password"
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        sx={{fontSize: 24}}
                        position="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {' '}
                        {showPassword ? (
                          <Visibility className="cursor_pointer" />
                        ) : (
                          <VisibilityOff />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setErr('')
                    setPwd(e.target.value)
                  }}
                ></WbTextField>
                {pErr && <div style={{color: 'red'}}>{pErr}</div>}

                <div className="pink-link">Forgot your password?</div>
                <Button
                  className={lockScreen ? 'grey-button' : 'login-button'}
                  onClick={onLogin}
                  disabled={lockScreen}
                >
                  Login
                </Button>
                {lockScreen && (
                  <CircularProgress size={24} className="login-button-lock" />
                )}
              </Stack>
            </div>
          </div>
        </Grid>
      </Grid> */}
    </>
  )
}
