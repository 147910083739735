import {Button} from '@mui/material'
import React from 'react'
import {CARD_COLORS} from '../../utils/constants'

export default function PupilSurveyCard({survey, index}) {
  const color = CARD_COLORS[index % 5]

  return (
    <div className="pupil-survey-card" style={{ backgroundColor: color, content: index + 1,}}>
      <div className="pupil-survey-name">
        <h2>{survey.name}</h2>
        <h4><strong>Due Date:</strong> {survey.dueDate}</h4>
      </div>
      <div className="start-survey-btn">
        <Button
          variant="contained"
          style={{color: color}}
          onClick={() => (window.location.href = survey.url)}
          disableElevation
          disableRipple
        >
          Start Survey
        </Button>
      </div>
      <div className="card-index">
        {index + 1}
      </div>
    </div>
  )
}
