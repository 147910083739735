import {Container, FormControlLabel, Grid, Stack, Skeleton} from '@mui/material'
import React, {useEffect, useState} from 'react'
import WbButton from '../../../components/common/WbButton'
import WbOutlineButton from '../../../components/common/WbOutlineButton'
import {useNavigate, useParams} from 'react-router-dom'
import paths from '../../../routes/paths'
import WbSwitch from '../../../components/common/WbSwitch'
import ParticipantCard from './ParticipantCard'
import SchoolFilter from './SchoolFilter'
import StudentParentFilter from './StudentParentFilter'
import StaffFilter from './StaffFilter'
import {participantsApi, surveysApi} from '../../../services'
import _, {set} from 'lodash'
import {AUDIENCE, CUSTOMER_TYPE} from '../../../utils/constants'
import PublishButton from '../../../components/screens/surveyForms/PublishButton'
import useCustomerData from '../../../hooks/useCustomerData'
import WbModal from '../../../components/common/WbModal'
import WbAlert from '../../../components/common/WbAlert'
import {schedulerApi} from '../../../services'

export default function SurveyParticipants() {
  const {token} = useParams()
  const navigate = useNavigate()

  const [survey, setSurvey] = useState(null)
  const [studentParent, setStudentParent] = useState([])
  const [staff, setStaff] = useState([])
  const [filteredStudentParent, setFilteredStudentParent] = useState([])
  const [filteredStaff, setFilteredStaff] = useState([])
  const [staffCount, setStaffCount] = useState(0)
  const [studentCount, setStudentCount] = useState(0)
  const [parentCount, setParentCount] = useState(0)

  const [availableFitlers, setAvailableFitlers] = useState([])
  const [availableSubfilters, setAvailableSubfilter] = useState([])
  const [isStaffSurvey, setIsStaffSurvey] = useState(false)
  const [isParentSurvey, setIsParentSurvey] = useState(false)
  const [isStudentSurvey, setIsStudentSurvey] = useState(false)
  const [availableStaffFilters, setAvailableStaffFilters] = useState([])
  const [availableStaffSubfilters, setAvailableStaffSubfilter] = useState([])

  const [participantFilter, setParticipantFilter] = useState({
    all: true,
    allSchools: true,
    schoolIds: [],

    allStudents: true,
    studentFilters: [
      {
        join: null,
        filter: null,
        operator: null,
        values: null,
      },
    ],

    allStaff: true,
    staffFilters: [
      {
        join: null,
        filter: null,
        operator: null,
        values: null,
      },
    ],
  })

  const [list, setList] = useState() //needs to getch from people management
  const [schools, setSchools] = useState([])
  const customer = useCustomerData()
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [loadingStudents, setLoadingStudents] = useState(false)
  const [showLessThanTenAlertOnPublish, setShowLessThanTenAlertOnPublish] =
    useState(false)

  useEffect(() => {
    surveysApi.get(token).then(survey => {
      setSurvey(survey)
      let isS = false
      let isP = false
      let isSt = false

      if (
        survey.audience === 1 ||
        survey.audience === 3 ||
        survey.audience === 5 ||
        survey.audience === 7
      ) {
        setIsStaffSurvey(true)
        isS = true
      }
      if (
        survey.audience === 2 ||
        survey.audience === 3 ||
        survey.audience === 6 ||
        survey.audience === 7
      ) {
        setIsParentSurvey(true)
        isP = true
      }
      if (
        survey.audience === 4 ||
        survey.audience === 5 ||
        survey.audience === 6 ||
        survey.audience === 7
      ) {
        setIsStudentSurvey(true)
        isSt = true
      }

      if (customer.customerType === CUSTOMER_TYPE.MAT) {
        participantsApi.getSchoolsByMatId(customer.id).then(res => {
          setSchools(res)
          let sIds = []
          res.map(item => sIds.push(item.id))
          setParticipantFilter({
            ...participantFilter,
            schoolIds: [...sIds],
            studentFilters: [...participantFilter.studentFilters],
            staffFilters: [...participantFilter.staffFilters],
          })

          //////////

          if (isSt || isP) {
            setLoadingStudents(true)
            participantsApi.getStudentParent(sIds).then(data => {
              createFilters(data)
            })
          } else {
            setLoadingStudents(false)
          }
          if (isS) {
            setLoadingFilters(true)
            participantsApi
              .getStaff(sIds)
              .then(res => {
                createStaffFilters(res)
                if (survey.participantFilter)
                  setParticipantFilter(JSON.parse(survey.participantFilter))
              })
              .catch(ee => {})
          } else {
            setLoadingFilters(false)
          }
        })
      } else {
        setSchools([{name: customer.name, id: customer.id}])

        setParticipantFilter({
          ...participantFilter,
          schoolIds: [customer.id],
          studentFilters: [...participantFilter.studentFilters],
          staffFilters: [...participantFilter.staffFilters],
        })

        if (isSt || isP) {
          participantsApi.getStudentParent([customer.id]).then(res => {
            createFilters(res)
          })
        }
        if (isS) {
          participantsApi.getStaff([customer.id]).then(res => {
            createStaffFilters(res)
          })
        }
      }
      if (survey.participantFilter)
        setParticipantFilter(JSON.parse(survey.participantFilter))
    })
  }, [])

  const createStaffFilters = res => {
    setFilteredStaff(res)
    setStaff(res)
    let fList = []
    res.forEach(item => {
      item.coreFilters.map(filter => {
        if (
          fList.findIndex(
            x => x.type === 'Department' && x.name === filter.department
          ) === -1 &&
          filter.department &&
          filter.department !== null
        ) {
          fList.push({
            type: 'Department',
            id: filter.department,
            name: filter.department,
          })
        }

        if (
          fList.findIndex(
            x => x.type === 'Job Type' && x.name === filter.employmentType
          ) === -1 &&
          filter.employmentType &&
          filter.employmentType > 0
        ) {
          fList.push({
            type: 'Job Type',
            id: filter.employmentType,
            name: filter.employmentType === 1 ? 'Full Time' : 'Part Time',
          })
        }
        if (filter.jobRole && filter.jobRole > 0) {
          if (
            fList.findIndex(
              x => x.type === 'Job Role' && x.id === filter.jobRole + ''
            ) === -1
          ) {
            fList.push({
              type: 'Job Role',
              id: filter.jobRole + '',
              name: getJobRoles(filter.jobRole),
            })
          }
        }
        if (filter.jobTitle && filter.jobTitle !== '') {
          if (
            fList.findIndex(
              x => x.type === 'Job Title' && x.name === filter.jobTitle
            ) === -1
          ) {
            fList.push({
              type: 'Job Title',
              id: filter.jobTitle,
              name: filter.jobTitle,
            })
          }
        }

        if (filter.phaseYear && filter.phaseYear !== null) {
          if (
            fList.findIndex(
              x => x.type === 'Key Stage' && x.id === filter.phaseYear
            ) === -1
          ) {
            fList.push({
              type: 'Key Stage',
              id: filter.phaseYear,
              name: filter.phaseYear,
            })
          }
        }

        if (filter.timeAtSchool && filter.timeAtSchool > 0) {
          if (
            fList.findIndex(
              x => x.type === 'Time At School' && x.id === filter.timeAtSchool
            ) === -1
          ) {
            fList.push({
              type: 'Time At School',
              id: filter.timeAtSchool,
              name: filter.timeAtSchool,
            })
          }
        }
      })
    })
    fList = [...fList.sort((a, b) => a.id - b.id)]
    setAvailableStaffFilters(_.uniqBy(fList, 'type'))
    setAvailableStaffSubfilter([...fList])

    setLoadingFilters(false)
  }
  const getJobRoles = roleId => {
    switch (roleId) {
      case 4:
        return 'Teaching Staff (Classroom)'

      case 8:
        return 'Teaching Staff (Non-Classroom)'

      case 16:
        return 'Support Staff (Classroom)'

      case 32:
        return 'Support Staff (Non-Classroom)'

      case 64:
        return 'Middle Leader'

      case 128:
        return 'Senior Leader'
    }
  }
  const createFilters = res => {
    setStudentParent(res)

    let fList = []

    res.forEach(item => {
      if (
        fList.findIndex(
          x =>
            x.type === 'Current Year Grade' && x.name === item.currentYearGrade
        ) === -1
      ) {
        fList.push({
          type: 'Current Year Grade',
          id: item.currentYearGrade,
          name: item.currentYearGrade,
        })
      }

      if (
        item.studentGender !== null &&
        fList.findIndex(
          x => x.type === 'Gender' && x.name === item.studentGender
        ) === -1
      ) {
        debugger
        fList.push({
          type: 'Gender',
          id: item.studentGender,
          name: item.studentGender,
        })
      }

      // if (
      //   item.dateOfBirth !== null &&
      //   fList.findIndex(
      //     x => x.type === 'Date Of Birth' && x.name === item.dateOfBirth
      //   ) === -1
      // ) {
      //   fList.push({
      //     type: 'Date Of Birth',
      //     id: item.dateOfBirth,
      //     name: item.dateOfBirth,
      //   })
      // }

      // if (
      //   item.AdmissionDate !== null &&
      //   fList.findIndex(
      //     x => x.type === 'Admission Date' && x.name === item.admissionDate
      //   ) === -1
      // ) {
      //   fList.push({
      //     type: 'Admission Date',
      //     id: item.admissionDate,
      //     name: item.admissionDate,
      //   })
      // }

      if (
        item.fsm !== null &&
        fList.findIndex(
          x => x.type === 'Free School Meal' && x.id === item.fsm
        ) === -1
      ) {
        fList.push({
          type: 'Free School Meal',
          id: item.fsm,
          name: item.fsm === 1 ? 'Eligible' : 'Not Eligible',
        })
      }

      if (
        item.pupilPremium !== null &&
        fList.findIndex(
          x => x.type === 'Pupil Premium' && x.id === item.pupilPremium
        ) === -1
      ) {
        fList.push({
          type: 'Pupil Premium',
          id: item.pupilPremium,
          name: item.pupilPremium === 1 ? 'Eligible' : 'Not Eligible',
        })
      }
      if (
        item.ealStatus !== null &&
        fList.findIndex(
          x => x.type === 'EAL Status' && x.id === item.ealStatus
        ) === -1
      ) {
        fList.push({
          type: 'EAL Status',
          id: item.ealStatus,
          name: item.ealStatus === 1 ? 'EAL' : 'Not EAL',
        })
      }
      if (
        item.serviceChild !== null &&
        fList.findIndex(
          x => x.type === 'Service Child' && x.id === item.serviceChild
        ) === -1
      ) {
        fList.push({
          type: 'Service Child',
          id: item.serviceChild,
          name: item.serviceChild === 1 ? 'Service Child' : 'Non-Service Child',
        })
      }

      if (
        item.giftedAndTalented !== null &&
        fList.findIndex(
          x =>
            x.type === 'Gifted And Talented' && x.id === item.giftedAndTalented
        ) === -1
      ) {
        fList.push({
          type: 'Gifted And Talented',
          id: item.giftedAndTalented,
          name: item.giftedAndTalented === 1 ? 'Yes' : 'No',
        })
      }

      if (
        item.specialEducationNeedsStatus !== null &&
        fList.findIndex(x =>
          x.type === 'Special Education Needs Status' &&
          (x.name === item.specialEducationNeedsStatus) === 1
            ? 'Yes'
            : 'No'
        ) === -1
      ) {
        fList.push({
          type: 'Special Education Needs Status',
          id: item.specialEducationNeedsStatus,
          name: item.specialEducationNeedsStatus,
        })
      }

      if (
        item.everInCare !== null &&
        fList.findIndex(
          x => x.type === 'Ever In Care' && x.id === item.everInCare
        ) === -1
      ) {
        fList.push({
          type: 'Ever In Care',
          id: item.everInCare,
          name:
            item.everInCare === 'true' ? 'Been in Care' : 'Never Been in Care',
        })
      }

      if (
        item.house !== null &&
        fList.findIndex(x => x.type === 'House' && x.name === item.house) === -1
      ) {
        fList.push({
          type: 'House',
          id: item.house,
          name: item.house,
        })
      }

      if (
        item.registration !== null &&
        fList.findIndex(
          x => x.type === 'Registration' && x.name === item.registration
        ) === -1
      ) {
        fList.push({
          type: 'Registration',
          id: item.registration,
          name: item.registration,
        })
      }

      if (
        item.campus !== null &&
        item.campus !== '' &&
        fList.findIndex(x => x.type === 'Campus' && x.name === item.campus) ===
          -1
      ) {
        fList.push({
          type: 'Campus',
          id: item.campus,
          name: item.campus,
        })
      }

      if (
        item.boardingStatus !== null &&
        fList.findIndex(
          x => x.type === 'Boarding Status' && x.id === item.boardingStatus
        ) === -1
      ) {
        fList.push({
          type: 'Boarding Status',
          id: item.boardingStatus,
          name: item.boardingStatus === 1 ? 'Boarding Student' : 'Day Student',
        })
      }

      item.achievements.map(i => {
        if (
          fList.findIndex(
            x =>
              //x.id === i.achievementWondeID &&
              x.type === 'Achievement' && x.name === i.achievementType
          ) === -1
        )
          fList.push({
            type: 'Achievement',
            id: i.achievementWondeID,
            name: i.achievementType,
          })
      })

      item.behaviours.map(i => {
        if (
          fList.findIndex(
            x =>
              //x.id === i.behaviourWondeID &&
              x.type === 'Behaviour' && x.name === i.behaviourType
          ) === -1
        )
          if (i.behaviourType !== '') {
            fList.push({
              type: 'Behaviour',
              id: i.behaviourWondeID,
              name: i.behaviourType,
            })
          }
      })

      item.classes.map(i => {
        if (
          fList.findIndex(
            x =>
              //x.id === i.classWondeID &&
              x.type === 'Class' && x.name === i.className
          ) === -1
        )
          fList.push({type: 'Class', id: i.classWondeID, name: i.className})
      })

      // item.attendance?.map(i => {
      //   if (
      //     fList.findIndex(
      //       x =>
      //         //x.id === i.attendanceId &&
      //         x.type === 'Attendence' && x.name === i.presentMarks + '%'
      //     ) === -1
      //   )
      //     fList.push({
      //       type: 'Attendence',
      //       id: i.attendanceId,
      //       name: i.presentMarks + '%',
      //     })
      // })

      item.subjects?.map(i => {
        if (
          fList.findIndex(
            x =>
              //x.id === i.subjectWondeID &&
              x.type === 'Subject' && x.name === i.subjectText
          ) === -1
        ) {
          fList.push({
            type: 'Subject',
            id: i.subjectWondeID,
            name: i.subjectText,
          })
        }
      })
    })
    if (
      survey?.audience === 2 ||
      survey?.audience === 3 ||
      survey?.audience === 6 ||
      survey?.audience === 7
    ) {
      fList.push({type: 'Parent', id: 1, name: 'Father'})
      fList.push({type: 'Parent', id: 2, name: 'Mother'})
    }
    var ll = _.uniqBy(fList, 'type')

    setAvailableFitlers([
      ...ll.sort((a, b) => {
        return a.type < b.type ? -1 : 1
      }),
    ])

    setAvailableSubfilter([...fList.sort((a, b) => (a.name < b.name ? -1 : 1))])
    setLoadingStudents(false)
  }

  const redirect = path => {
    navigate(path.replace(':token', encodeURIComponent(token)), {
      state: {survey: {...survey}},
    })
  }

  const performFilter = () => {
    if (isStudentSurvey || isParentSurvey) {
      setFilteredStudentParent([...filterStudentParentList()])
    }
    if (isStaffSurvey) {
      setFilteredStaff([...filterStaffList()])
    }
  }
  useEffect(
    () => performFilter(),
    [
      staff,
      studentParent,
      isParentSurvey,
      isStaffSurvey,
      isStudentSurvey,
      participantFilter,
    ]
  )

  const filterStaffList = () => {
    let nfl = staff
    if (!participantFilter.allSchools) {
      nfl = [
        ...nfl.filter(st => participantFilter.schoolIds.includes(st.schoolId)),
      ]
    }
    if (!participantFilter.allStaff) {
      participantFilter.staffFilters?.forEach(filter => {
        if (
          filter.values !== null &&
          filter.values.length > 0 &&
          filter.filter !== null &&
          filter.operator !== null
        ) {
          if (filter.join === 'and' || filter.join === null) {
            nfl = [...staffFilterList(nfl, filter)]
          } else if (filter.join === 'or' || filter.join === null) {
            nfl = [...nfl, ...staffFilterList(staff, filter)]
          }
        }
      })
    }

    return nfl
  }

  const staffFilterList = (nfl, filter) => {
    let ss = []

    nfl.filter(staff => {
      if (filter.filter === 'Job Role') {
        let rr = staff.coreFilters.filter(x =>
          filter.operator === 'is'
            ? filter.values.includes(x.jobRole + '')
            : !filter.values.includes(x.jobRole + '')
        )
        if (rr.length > 0) {
          ss = [...ss, {...staff, coreFilters: rr}]
        }
      }
      if (staff.coreFilters) {
        if (filter.filter === 'Job Title') {
          let rr = staff.coreFilters.filter(x =>
            filter.operator === 'is'
              ? filter.values.includes(x.jobTitle)
              : !filter.values.includes(x.jobTitle)
          )
          if (rr.length > 0) {
            ss = [...ss, {...staff, coreFilters: rr}]
          }
        }

        if (filter.filter === 'Department') {
          let rr = staff.coreFilters.filter(x =>
            filter.operator === 'is'
              ? filter.values.includes(x.department)
              : !filter.values.includes(x.department)
          )
          if (rr.length > 0) {
            ss = [...ss, {...staff, coreFilters: rr}]
          }
        }

        if (filter.filter === 'Key Stage') {
          let rr = staff.coreFilters.filter(x =>
            filter.operator === 'is'
              ? filter.values.includes(x.phaseYear)
              : !filter.values.includes(x.phaseYear)
          )
          ss = [...ss, ...rr]
        }
        if (filter.filter === 'Time At School') {
          let rr = staff.coreFilters.filter(x =>
            filter.operator === 'is'
              ? filter.values.includes(x.timeAtSchool)
              : !filter.values.includes(x.timeAtSchool)
          )
          if (rr.length > 0) {
            ss = [...ss, {...staff, coreFilters: rr}]
          }
        }
      }
    })

    return ss
  }

  const filterStudentParentList = () => {
    let nfl = studentParent
    if (!participantFilter.allSchools) {
      nfl = [
        ...studentParent.filter(student =>
          participantFilter.schoolIds.includes(student.schoolId)
        ),
      ]
    }

    if (!participantFilter.allStudents) {
      participantFilter.studentFilters?.forEach(filter => {
        if (
          filter.values !== null &&
          filter.values.length > 0 &&
          filter.filter !== null &&
          filter.operator !== null
        )
          if (filter.join === 'and' || filter.join === null) {
            nfl = filterList(nfl, filter)
          } else if (filter.join === 'or' || filter.join === null) {
            nfl = [...nfl, ...filterList(studentParent, filter)]
          }
      })
    }
    return nfl
  }

  const filterList = (nfl, filter) => {
    let ss = []
    nfl.filter(student => {
      if (
        filter.filter === 'Current Year Grade' &&
        filter.values.filter(x => x == student.currentYearGrade).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Gender' &&
        filter.values.filter(x => x == student.studentGender).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Date Of Birth' &&
        filter.values.filter(x => x == student.dateOfBirth).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Admission Birth' &&
        filter.values.filter(x => x == student.admissionDate).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Free School Meal' &&
        filter.values.filter(
          x => x === (student.fsm === 1 ? 'Eligible' : 'Not Eligible')
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Pupil Premium' &&
        filter.values.filter(
          x => x === (student.pupilPremium === 1 ? 'Eligible' : 'Not Eligible')
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      // if (
      //   filter.filter === 'Pupil Premium' &&
      //   filter.values.filter(
      //     x => x === (student.pupilPremium === 1 ? 'Eligible' : 'Not Eligible')
      //   ).length > 0
      // ) {
      //   ss = [...ss, student]
      // }
      debugger
      if (
        filter.filter === 'EAL Status' &&
        filter.values.filter(
          x => x === (student.ealStatus === 1 ? 'EAL' : 'Not EAL')
        ).length > 0
      ) {
        debugger
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Service Child' &&
        filter.values.filter(
          x =>
            x ===
            (student.serviceChild === 1 ? 'Service Child' : 'Non-Service Child')
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Gifted And Talented' &&
        filter.values.filter(
          x => x === (student.giftedAndTalented === 1 ? 'Yes' : 'No')
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Special Education Needs Status' &&
        filter.values.filter(x => x === student.specialEducationNeedsStatus)
          .length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Ever In Care' &&
        filter.values.filter(
          x =>
            x ===
            (student.everInCare === 'true'
              ? 'Been in Care'
              : 'Never Been in Care')
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'House' &&
        filter.values.filter(x => x === student.house).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'House' &&
        filter.values.filter(x => x === student.house).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Registration' &&
        filter.values.filter(x => x === student.registration).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Campus' &&
        filter.values.filter(x => x === student.campus).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Boarding Status' &&
        filter.values.filter(
          x =>
            x ===
            (student.boardingStatus === 1 ? 'Boarding Student' : 'Day Student')
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      // if (
      //   filter.filter === 'Attendence' &&
      //   student.attendance?.filter(attendance =>
      //     filter.operator === 'is'
      //       ? filter.values.includes(attendance.presentMarks + '%')
      //       : !filter.values.includes(attendance.presentMarks + '%')
      //   ).length > 0
      // ) {
      //   ss = [...ss, student]
      // }

      if (
        filter.filter === 'Achievement' &&
        student.achievements?.filter(achievement =>
          filter.operator === 'is'
            ? filter.values.includes(achievement.achievementType)
            : !filter.values.includes(achievement.achievementType)
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Subject' &&
        student.subjects?.filter(x =>
          filter.operator === 'is'
            ? filter.values.includes(x.subjectText)
            : !filter.values.includes(x.subjectText)
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Class' &&
        student.classes?.filter(x =>
          filter.operator === 'is'
            ? filter.values.includes(x.className)
            : !filter.values.includes(x.className)
        ).length > 0
      ) {
        ss = [...ss, student]
      }

      if (
        filter.filter === 'Behaviour' &&
        student.behaviours?.filter(x =>
          filter.operator === 'is'
            ? filter.values.includes(x.behaviourType)
            : !filter.values.includes(x.behaviourType)
        ).length > 0
      ) {
        ss = [...ss, student]
      }
    })
    return ss
  }

  const updateParentCount = count => {
    setParentCount(count)
  }
  const updateStudentCount = count => {
    setStudentCount(count)
  }
  const updateStaffCount = count => {
    setStaffCount(count)
  }

  const [showLessThan10Alert, setShowLessThan10Alert] = useState(false)

  const saveAndNext = () => {
    if (
      ((isStaffSurvey && staffCount < 10) ||
        (isStudentSurvey && studentCount < 10) ||
        (isParentSurvey && parentCount < 10)) &&
      survey.anonimous
    ) {
      setShowLessThan10Alert(true)
      return
    }
    saveParticipants()
  }

  const publish = () => {
    setShowLessThanTenAlertOnPublish(true)
  }

  const saveParticipants = () => {
    surveysApi
      .updateParticipants(
        token,
        {...participantFilter},
        parentCount,
        staffCount,
        studentCount
      )
      .then(res => {
        localStorage.removeItem('RedirectUrl')
        redirect(paths.invitesPanel)
      })
      .catch(err => console.error(err))
  }

  return (
    <Container maxWidth="lg">
      {showLessThanTenAlertOnPublish && (
        <WbModal
          title="Less than 10 participants in an audience for an anonymous survey"
          onClose={() => setShowLessThanTenAlertOnPublish(false)}
          content={
            <>
              <p>
                You have less than 10 participants in an audience for an
                anonymous survey. Do you want to make it none anonimous and
                publish it?
              </p>
              <div style={{textAlign: 'right'}}>
                <WbOutlineButton
                  CustomOutlineButtonText="No"
                  onClick={() => setShowLessThanTenAlertOnPublish(false)}
                ></WbOutlineButton>{' '}
                <WbButton
                  CustomButtonText={'Yes'}
                  onClick={async () => {
                    let res = await schedulerApi.publish(token)
                    if (res) {
                      surveysApi.UpdateSurveyAnnonymise(survey).then().catch()
                      WbAlert({
                        message: 'Survey published successfully',
                        type: 'success',
                      })
                      //
                      navigate(paths.dashboard)
                    } else {
                      WbAlert({
                        message: 'Failed to publish survey',
                        type: 'error',
                      })
                    }
                  }}
                />
              </div>
            </>
          }
        />
      )}
      {showLessThan10Alert && (
        <WbModal
          onClose={() => setShowLessThan10Alert(false)}
          title={'Less than 10 Participants for an anonymous survey'}
          content={
            <>
              <p>
                This survey is anonymous so you need at least 10 participants
                for each audience
              </p>
              <div style={{textAlign: 'right'}}>
                <WbOutlineButton
                  CustomOutlineButtonText={'Cancel'}
                  onClick={e => setShowLessThan10Alert(false)}
                />{' '}
                <WbButton
                  CustomButtonText={'Continue'}
                  onClick={w => {
                    setShowLessThan10Alert(false)

                    saveParticipants()
                  }}
                />
              </div>
            </>
          }
        />
      )}

      <div className="body-container participants">
        {loadingFilters || loadingStudents ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>Participants</h1>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
              <FormControlLabel
                control={
                  <WbSwitch
                    label={'All Participants'}
                    checked={participantFilter.all}
                    onChange={e => {
                      setParticipantFilter({
                        ...participantFilter,
                        all: e.target.checked,
                        schoolIds: [...schools.map(x => x.id)],
                      })
                      setFilteredStaff(staff)
                    }}
                  />
                }
                label={'All Participants'}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="participant-filter-box">
                {!participantFilter.all && (
                  <Grid item lg={12} md={12} sm={12} xs={12} mt={2} mb={1}>
                    <h3>Filter Participants</h3>
                  </Grid>
                )}
                {!participantFilter.all &&
                  customer.customerType === CUSTOMER_TYPE.MAT && (
                    <div className="participant-filter">
                      <SchoolFilter
                        participantFilter={participantFilter}
                        setParticipantFilter={setParticipantFilter}
                        schools={schools}
                      />
                    </div>
                  )}
                {!participantFilter.all &&
                  (isParentSurvey || isStudentSurvey) && (
                    <div className="participant-filter">
                      <StudentParentFilter
                        participantFilter={participantFilter}
                        setParticipantFilter={setParticipantFilter}
                        availableFitlers={availableFitlers}
                        availableSubfilters={availableSubfilters}
                      />
                    </div>
                  )}
                {!participantFilter.all && isStaffSurvey && (
                  <div className="participant-filter">
                    <StaffFilter
                      participantFilter={participantFilter}
                      setParticipantFilter={setParticipantFilter}
                      availableFitlers={availableStaffFilters}
                      availableSubfilters={availableStaffSubfilters}
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                mb={10}
                mt={1}
              >
                {isStaffSurvey && (
                  <Grid item lg={3} md={4} sm={12} xs={12}>
                    <ParticipantCard
                      type={'Staff'}
                      list={filteredStaff}
                      updateCount={updateStaffCount}
                    />
                  </Grid>
                )}
                {isParentSurvey && (
                  <Grid item lg={3} md={4} sm={12} xs={12}>
                    <ParticipantCard
                      type={'Parents'}
                      list={filteredStudentParent}
                      updateCount={updateParentCount}
                    />
                  </Grid>
                )}

                {isStudentSurvey && (
                  <Grid item lg={3} md={4} sm={12} xs={12}>
                    <ParticipantCard
                      type={'Students'}
                      list={filteredStudentParent}
                      updateCount={updateStudentCount}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <WbOutlineButton
                  CustomOutlineButtonText={'Previous'}
                  onClick={e => redirect(paths.questionPanel)}
                />
                <div>
                  <Stack direction="row" spacing={1}>
                    <WbOutlineButton
                      CustomOutlineButtonText={'Cancel'}
                      onClick={e => redirect(paths.dashboard)}
                    />
                    <WbButton
                      CustomButtonText={'Save and Next'}
                      onClick={saveAndNext}
                    />
                    <PublishButton
                      survey={survey}
                      onSave={publish}
                    ></PublishButton>
                  </Stack>
                </div>
              </Stack>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  )
}
