import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {Provider, useSelector} from 'react-redux'
import {store} from './redux/store'
import userManager, {loadUserFromStorage} from './services/api/user/userApi'
import AuthProvider from './utils/authProvidor'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import ProtectedRoute from './routes/protectedRoutes'
import paths from './routes/paths.js'

import SigninOidc from './views/Account/SignIn'
import Base from './components/layouts/Base'
import Login from './views/Account/Login'
import Dashboard from './views/Dashboard'
import NotFound from './views/NotFound.jsx'
import SurveyPanel from './components/layouts/SurveyPanel'
import QuestionPanel from './views/SurveyPanel/QuestionPanel'
import SurveyTemplates from './views/SurveyTemplates'
import InvitesPanel from './views/SurveyPanel/InvitesPanel'
import SchedulePanel from './views/SurveyPanel/SchedulePanel'
import SurveyView from './views/SurveyView'
import SurveyParticipants from './views/SurveyPanel/SurveyParticipants'
import ResultsLayout from './components/layouts/ResultsLayout'
import Comments from './views/ResultsPanel/Comments'
import ParticipantReply from './views/ResultsPanel/Comments/ParticipantReply.jsx'
import Questions from './views/SuperAdmin/Questions'
import Redirect from './views/SuperAdmin/Redirect.jsx'
import EmojiLogin from './views/PupilDashboard/Login/Emoji/EmojiLogin.jsx'
import PupilSurveys from './views/PupilDashboard/PupilSurveys.jsx'
import QuestionTags from './views/SuperAdmin/Questions/QuestionTags.jsx'

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ['Inter, sans-serif'].join(','),
    },
  })

  useEffect(() => {
    const skipStorage = ['/login', '/login-redirect', '/']
    if (!skipStorage.includes(window.location.pathname)) {
      var url = window.location.pathname + window.location.search
      localStorage.setItem('RedirectUrl', url)
    }

    loadUserFromStorage(store)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthProvider userManager={userManager} store={store}>
          <Router>
            <Routes>
              <Route path={paths.login} element={<Login />} />
              <Route path={paths.loginRedirect} element={<SigninOidc />} />
              <Route element={<ProtectedRoute />}>
                <Route element={<Base />}>
                  <Route path={paths.dashboard} element={<Dashboard />} />
                  <Route element={<SurveyPanel />}>
                    <Route
                      path={paths.questionPanel}
                      element={<QuestionPanel />}
                    />
                    <Route
                      path={paths.surveyParticipants}
                      element={<SurveyParticipants />}
                    />
                    <Route
                      path={paths.invitesPanel}
                      element={<InvitesPanel />}
                    />
                    <Route
                      path={paths.schedulePanel}
                      element={<SchedulePanel />}
                    />
                  </Route>
                  <Route path={paths.resultsDash} element={<ResultsLayout />}>
                    <Route path={paths.comments} element={<Comments />} />
                  </Route>
                </Route>
              </Route>
              <Route
                path={paths.surveyTemplatesPanel}
                element={<SurveyTemplates />}
              />
              <Route path={paths.surveyView} element={<SurveyView />} />
              <Route
                path={paths.participantsReply}
                element={<ParticipantReply />}
              />
              <Route path={paths.superAdminRedirect} element={<Redirect />} />
              <Route path={paths.pupilDashLogin} element={<EmojiLogin />} />
              <Route path={paths.pupilSurveys} element={<PupilSurveys />} />
              <Route path={paths.question} element={<Questions />} />
              <Route path={paths.questiontag} element={<QuestionTags />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
