import {BarChart, Delete, Edit, MoreHoriz} from '@mui/icons-material'
import {
  Stack,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Grid,
} from '@mui/material'
import React from 'react'
import paths from '../../routes/paths'
import {useNavigate} from 'react-router-dom'
import surveysApi from '../../services/api/surveys/surveysApi'
import WbAlert from '../../components/common/WbAlert'
import {set} from 'lodash'
import ResendInvites from './ResendInvites'
import WbModal from '../../components/common/WbModal'
import GroupCyclesList from './GroupCyclesList'
import ParticipantCount from './ParticipantCount'
import WbOutlineButton from '../../components/common/WbOutlineButton'
import WbButtom from '../../components/common/WbButton'

export default function SurveyCard({survey, updateListOnDelete, loadSurveys}) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [showResendInvites, setShowResendInvites] = React.useState(false)
  const [deleteConfirm, setDeleteConfirm] = React.useState(false)
  const handleClose = event => {
    setAnchorEl(null)
  }
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const getAudienceChip = audience => {
    switch (audience) {
      case 1:
        return <Chip className="audience-chip" label={'Staff'} size="small" />
      case 2:
        return <Chip className="audience-chip" label={'Parents'} size="small" />
      case 3:
        return (
          <>
            <Chip className="audience-chip" label={'Staff'} size="small" />
            <Chip className="audience-chip" label={'Parent'} size="small" />
          </>
        )
      case 4:
        return (
          <Chip className="audience-chip" label={'Students'} size="small" />
        )
      case 5:
        return (
          <>
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Staff'} size="small" />
          </>
        )
      case 6:
        return (
          <>
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        )
      default:
        return (
          <>
            <Chip className="audience-chip" label={'Staff'} size="small" />
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        )
    }
  }

  const deleteSurvey = async () => {
    setDeleteConfirm(false)
    surveysApi.delete(survey.token).then(() => {
      setAnchorEl(null)
      updateListOnDelete()
      WbAlert({message: 'Survey deleted successfully', type: 'success'})
    })
  }

  const getTag = tag => {
    if (tag?.length > 20) {
      return tag.slice(0, 20) + '...'
    }
    return tag
  }

  return (
    <div className="surveys">
      {deleteConfirm && (
        <WbModal
          title="Delete Survey"
          onClose={e => setDeleteConfirm(false)}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Are you sure you want to delete this survey?
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                }}
                spacing={2}
              >
                <WbOutlineButton
                  CustomOutlineButtonText={'Cancel'}
                  onClick={e => setDeleteConfirm(false)}
                />
                &nbsp; &nbsp;
                <WbButtom CustomButtonText={'Delete'} onClick={deleteSurvey} />
              </Grid>
            </Grid>
          }
        ></WbModal>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <div className="surveyname">
          {survey?.name.length > 30
            ? survey?.name.slice(0, 30) + '...'
            : survey?.name}
        </div>
        <div>
          <Stack direction="row">
            {survey.status !== 'Live' && survey.status !== 'Closed' && (
              <IconButton
                onClick={() =>
                  navigate(
                    paths.questionPanel.replace(
                      ':token',
                      encodeURIComponent(survey.token)
                    ),
                    {state: {survey: survey}}
                  )
                }
                disableRipple
              >
                <Edit sx={{fontSize: '18px', marginRight: '10px'}} />
              </IconButton>
            )}
            {survey.status !== 'Closed' && (
              <IconButton onClick={handleClick} disableRipple>
                <MoreHoriz sx={{fontSize: '24px'}} />
              </IconButton>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className="options-menu"
            >
              {survey.status !== 'Live' && survey.status !== 'Closed' && (
                <MenuItem
                  onClick={e => {
                    setAnchorEl(null)
                    setDeleteConfirm(true)
                  }}
                >
                  Delete
                </MenuItem>
              )}
              {survey.status === 'Live' && (
                <MenuItem onClick={e => setShowResendInvites(true)}>
                  Resend invites
                </MenuItem>
              )}
            </Menu>
          </Stack>
        </div>
      </Stack>
      {showResendInvites && (
        <WbModal
          title="Resend Invites"
          onClose={e => setShowResendInvites(false)}
          content={<ResendInvites survey={survey} />}
        ></WbModal>
      )}
      <Divider orientation="horizontal" />

      <div>
        <Stack direction="row" mt={2} justifyContent="space-between">
          <div className="survey-date">
            {survey.status === 'Closed' ? (
              <Button
                className="analyse-data-btn"
                startIcon={<BarChart />}
                style={{textTransform: 'none'}}
                onClick={e =>
                  window.open(
                    `https://ei.welbee.co.uk/EDUDashboard?CycleId=${survey.resultId}`,
                    '_blank'
                  )
                }
                disableElevation
                disableRipple
                disableFocusRipple
              >
                Analyse Data
              </Button>
            ) : survey.status === 'Live' ? (
              <Stack>
                <GroupCyclesList
                  token={survey.token}
                  loadSurveys={loadSurveys}
                  resultId={survey.resultId}
                />
                <ParticipantCount survey={survey} />
              </Stack>
            ) : (
              `Created on: ${new Date(Date.parse(survey.createdAt)).toDateString()}`
            )}
          </div>
          <div>
            <Chip
              label={survey.status}
              style={{
                borderColor: survey.status === 'Live' ? '#E84F6B' : '#007A7A',
                color: survey.status === 'Live' ? '#E84F6B' : '#007A7A',
              }}
              size="small"
              variant="outlined"
            />
          </div>
        </Stack>

        <div className="survey-bottom">
          <Stack direction="row" columnGap={0.5} flexWrap="wrap" rowGap={0.5}>
            {survey.surveysTags?.map(
              x =>
                x && (
                  <Chip
                    label={getTag(x)}
                    variant="outlined"
                    size="small"
                    className="tag-chip"
                  />
                )
            )}
          </Stack>

          <Stack direction="row" spacing={0.5} mt={1}>
            {getAudienceChip(survey.audience)}
          </Stack>
        </div>
      </div>
    </div>
  )
}
