import {styled} from '@mui/material/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  Button,
  Grid,
  Skeleton,
} from '@mui/material'

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '.schedule-survey-btn': {
    color: '#E84F6B',
  },
  '.schedule-survey-btn:hover': {
    backgroundColor: 'transparent',
  },
}))

export default StyledTableRow
