import {Grid} from '@mui/material'
import React, {useEffect} from 'react'
import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
} from '@mui/material'
import tagsApi from '../../../services/api/questionLibrary/tagsApi'
import questionsApi from '../../../services/api/questionLibrary/questionsApi'
import WbButton from '../../../components/common/WbButton'
import WbAlert from '../../../components/common/WbAlert'
import WbTextField from '../../../components/common/WbTextField'

export default function QuestionTags() {
  const [searchedQuestions, setSearchedQuestions] = React.useState([])
  const [tags, setTags] = React.useState([])
  const [allQuestions, setAllQuestions] = React.useState([])

  const [selectedTag, setSelectedTag] = React.useState([])
  const [selectedQuestions, setSelectedQuestions] = React.useState([])
  const [searchText, setSearchText] = React.useState('')

  useEffect(() => {
    questionsApi
      .getAll()
      .then(res => {
        setAllQuestions(res)
        setSearchedQuestions(res)
      })
      .catch(err => {
        console.log('error', err)
      })

    tagsApi.get().then(res => {
      debugger
      setTags(res)
    })
  }, [])
  return (
    <Grid container style={{padding: '20px', width: '80%', margin: '50px'}}>
      <Grid item xs={12}>
        <h1>Question Tags</h1>
      </Grid>
      <Grid item xs={6}>
        <WbTextField
          label="Search Questions"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        {/* <QuestionLibrary /> */}

        <Select
          fullWidth
          multiple
          value={selectedTag}
          onChange={e => setSelectedTag(e.target.value)}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={selectedTag => {
            debugger
            return (
              <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                {selectedTag.map(value => (
                  <Chip key={value.id} label={value.name} />
                ))}
              </Box>
            )
          }}
        >
          {tags?.map(tag => (
            <MenuItem key={tag.id} value={tag}>
              {tag.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {allQuestions
        .filter(f => {
          if (searchText.length > 0) {
            return (
              f.staffText.includes(searchText) ||
              f.parentText.includes(searchText) ||
              f.pupilText.includes(searchText)
            )
          }
          return true
        })
        .map(q => (
          <>
            <Grid item lg={2} key={q.id} textAlign={'right'}>
              <Checkbox
                value={selectedQuestions.includes(q.id)}
                onChange={e => {
                  if (selectedQuestions.includes(q.id)) {
                    setSelectedQuestions(
                      selectedQuestions.filter(id => id !== q.id)
                    )
                  } else {
                    setSelectedQuestions([...selectedQuestions, q.id])
                  }
                }}
              />
            </Grid>

            <Grid item lg={10} key={q.id}>
              <h3>
                <b>Staff Text: </b>
                {q.staffText} <b>PArent</b>: {q.parentText}
                <b>Student</b>: {q.pupilText}
              </h3>
              <p>{q.answerType}</p>
            </Grid>
          </>
        ))}
      <Grid item xs={12}>
        <WbButton
          CustomButtonText={'Map Questions to Tags'}
          onClick={e => {
            let val = {
              matId: 0,
              schoolId: 0,
              QuestionIds: [...selectedQuestions],
              tags: [...selectedTag.map(t => t.name)],
            }
            debugger
            tagsApi.tagQuestions(val)

            WbAlert({
              message: 'Questions tagged successfully',
              type: 'success',
            })
          }}
        />
      </Grid>
    </Grid>
  )
}
