import {PieChart} from '@mui/icons-material'
import {Button, CircularProgress, Stack} from '@mui/material'
import React, {useEffect} from 'react'
import WbModal from '../../components/common/WbModal'
import surveysApi from '../../services/api/surveys/surveysApi'
import HeaderTableCell from '../../components/common/StyledComponents/HeaderTableCell'
import StyledTableRow from '../../components/common/StyledComponents/StyledTableRow'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material'
import useCustomerData from '../../hooks/useCustomerData'

export default function ParticipantCount({survey}) {
  const customer = useCustomerData()
  const [showBreakdown, setShowBreakdown] = React.useState(false)
  const [schools, setSchools] = React.useState([])
  const [audience, setAudience] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [loadingDetails, setLoadingDetails] = React.useState(false)
  const [schoolDetails, setSchoolDetails] = React.useState([])
  const [user, setUser] = React.useState(null)
  const [selectedSchool, setSelectedSchool] = React.useState(null)
  const [selectedAudience, setSelectedAudience] = React.useState(null)

  const isStaffSurvey =
    survey.audience === 1 ||
    survey.audience === 3 ||
    survey.audience === 5 ||
    survey.audience === 7
  const isStudentSurvey =
    survey.audience === 4 ||
    survey.audience === 5 ||
    survey.audience === 6 ||
    survey.audience === 7

  const isParentSurvey =
    survey.audience === 2 ||
    survey.audience === 3 ||
    survey.audience === 6 ||
    survey.audience === 7

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem('user'))
    setUser(u)
    if (!u.isMat) {
      let customer = JSON.parse(localStorage.getItem('customer'))
      setSelectedSchool({schoolId: customer.id})
    }
  }, [])
  useEffect(() => {
    if (selectedSchool === null) return

    setLoadingDetails(true)
    surveysApi
      .getParticipationBreakdownBySchool(
        selectedSchool.groupCycleId,
        selectedSchool.schoolId,
        survey.id
      )
      .then(result => {
        setSchoolDetails(result)
        if (result) {
          let sch = []
          result.map(r =>
            sch.push({schoolId: r.schoolId, schoolName: r.schoolName})
          )
          setSchools([...sch])
        }
      })
      .finally(e => setLoadingDetails(false))
  }, [selectedSchool])

  const showSchool = () => (
    <Stack>
      <Stack direction="row">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 1.83334C16.0628 1.83334 20.1667 5.93726 20.1667 11C20.1667 16.0628 16.0628 20.1667 11 20.1667C5.93729 20.1667 1.83337 16.0628 1.83337 11C1.83337 5.93726 5.93729 1.83334 11 1.83334ZM11 3.66668V11H18.3334C18.3334 9.05509 17.5608 7.18983 16.1855 5.81456C14.8102 4.43929 12.945 3.66668 11 3.66668Z"
            fill="#45338C"
          />
        </svg>
      </Stack>
      {loading ? (
        <div style={{textAlign: 'right'}}>
          <CircularProgress />
        </div>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <HeaderTableCell>Cycles</HeaderTableCell>
              <HeaderTableCell>Staff</HeaderTableCell>
              <HeaderTableCell>Student</HeaderTableCell>
              <HeaderTableCell>Parent</HeaderTableCell>
              <HeaderTableCell></HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedSchool &&
              (loadingDetails ? (
                <CircularProgress />
              ) : (
                schoolDetails.map(e => (
                  <StyledTableRow>
                    <TableCell>{e.groupName}</TableCell>
                    <TableCell>{e.staffCount.toFixed(2)} %</TableCell>
                    <TableCell>{e.studentCount.toFixed(2)}%</TableCell>
                    <TableCell>{e.parentCount.toFixed(2)}%</TableCell>
                  </StyledTableRow>
                ))
              ))}
          </TableBody>
        </Table>
      )}
    </Stack>
  )

  const participationBreackdown = () => (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        columnGap={1}
        mt={2}
        mb={3}
        sx={{color: '#45338C'}}
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 1.83334C16.0628 1.83334 20.1667 5.93726 20.1667 11C20.1667 16.0628 16.0628 20.1667 11 20.1667C5.93729 20.1667 1.83337 16.0628 1.83337 11C1.83337 5.93726 5.93729 1.83334 11 1.83334ZM11 3.66668V11H18.3334C18.3334 9.05509 17.5608 7.18983 16.1855 5.81456C14.8102 4.43929 12.945 3.66668 11 3.66668Z"
            fill="#45338C"
          />
        </svg>
        Overall Participation: {survey.participantCount}%
        {/* <div style={{textAlign: 'right'}}>
          <WbTextField
            label="Search"
            select
            value={selectedSchool}
            onChange={e => setSelectedSchool(e.target.value)}
            options={data.map(e => e.schoolName)}
          />
        </div> */}
      </Stack>
      {loading ? (
        <div>
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        </div>
      ) : (
        <div className="participation-breakdown">
          <Table size="small">
            <TableHead>
              <TableRow>
                {user.isMat && <HeaderTableCell>School Name</HeaderTableCell>}
                {isStaffSurvey && <HeaderTableCell>Staff</HeaderTableCell>}
                {isStudentSurvey && <HeaderTableCell>Student</HeaderTableCell>}
                {isParentSurvey && <HeaderTableCell>Parent</HeaderTableCell>}
                <HeaderTableCell></HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <>
                  <StyledTableRow>
                    {user.isMat && <TableCell>{row.schoolName}</TableCell>}
                    {isStaffSurvey && (
                      <TableCell>{row.staffCount.toFixed(2)} %</TableCell>
                    )}
                    {isStudentSurvey && (
                      <TableCell>{row.studentCount.toFixed(2)}%</TableCell>
                    )}
                    {isParentSurvey && (
                      <TableCell>{row.parentCount.toFixed(2)}%</TableCell>
                    )}
                    <TableCell>
                      {user.isMat && (
                        <Button
                          className="view-cycles-btn"
                          onClick={e =>
                            selectedSchool &&
                            selectedSchool?.schoolId === row.schoolId
                              ? setSelectedSchool(null)
                              : setSelectedSchool(row)
                          }
                        >
                          {selectedSchool &&
                          selectedSchool?.schoolId === row.schoolId
                            ? 'Close'
                            : 'View Cycles'}
                        </Button>
                      )}
                    </TableCell>
                  </StyledTableRow>

                  {user.isMat &&
                    selectedSchool &&
                    (loadingDetails ? (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                      </>
                    ) : (
                      selectedSchool?.schoolId === row.schoolId &&
                      schoolDetails.map(e => (
                        <StyledTableRow>
                          <TableCell>{e.groupName}</TableCell>
                          {isStaffSurvey && (
                            <TableCell>{e.staffCount.toFixed(2)} %</TableCell>
                          )}
                          {isStudentSurvey && (
                            <TableCell>{e.studentCount.toFixed(2)}%</TableCell>
                          )}
                          {isParentSurvey && (
                            <TableCell>{e.parentCount.toFixed(2)}%</TableCell>
                          )}
                        </StyledTableRow>
                      ))
                    ))}
                </>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Stack>
  )

  const load = () => {
    setLoading(true)
    surveysApi
      .getParticipationBreakdown(survey.id)
      .then(result => setData(result))
      .catch(e => console.error(e))
      .finally(e => setLoading(false))
  }

  return (
    <>
      {showBreakdown && (
        <WbModal
          onClose={() => setShowBreakdown(false)}
          title="Overall Participation"
          content={
            // customer.customerType === CUSTOMER_TYPE.MAT
            //   ? showSchool()
            //   :
            participationBreackdown()
          }
        ></WbModal>
      )}

      <Button
        className="participation-data-btn"
        startIcon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.99999 1.66666C14.6025 1.66666 18.3333 5.39749 18.3333 9.99999C18.3333 14.6025 14.6025 18.3333 9.99999 18.3333C5.39749 18.3333 1.66666 14.6025 1.66666 9.99999C1.66666 5.39749 5.39749 1.66666 9.99999 1.66666ZM9.99999 3.33332V9.99999H16.6667C16.6667 8.23188 15.9643 6.53619 14.714 5.28594C13.4638 4.0357 11.7681 3.33332 9.99999 3.33332Z"
              fill="#45338C"
            />
          </svg>
        }
        style={{textTransform: 'none'}}
        onClick={() => {
          setShowBreakdown(true)
          load()
        }}
        disableElevation
        disableRipple
        disableFocusRipple
      >
        Overall Participation: {survey.participantCount}%
      </Button>
    </>
  )
}
