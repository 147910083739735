import airplane from '../components/assets/emojis/icons8-airplane-100.png'
import automobile from '../components/assets/emojis/icons8-automobile-100.png'
import backpack from '../components/assets/emojis/icons8-backpack-100.png'
import beach from '../components/assets/emojis/icons8-beach-with-umbrella-100.png'
import beatingHeart from '../components/assets/emojis/icons8-beating-heart-100.png'
import cake from '../components/assets/emojis/icons8-birthday-cake-emoji-100.png'
import butterfly from '../components/assets/emojis/icons8-butterfly-100.png'
import carrot from '../components/assets/emojis/icons8-carrot-100.png'
import castle from '../components/assets/emojis/icons8-castle-100.png'
import crown from '../components/assets/emojis/icons8-crown-100.png'
import doughnut from '../components/assets/emojis/icons8-doughnut-emoji-100.png'
import drum from '../components/assets/emojis/icons8-drum-100.png'
import face from '../components/assets/emojis/icons8-face-with-open-eyes-and-hand-over-mouth-100.png'
import fire from '../components/assets/emojis/icons8-fire-100.png'
import moon from '../components/assets/emojis/icons8-first-quarter-moon-face-100.png'
import glasses from '../components/assets/emojis/icons8-glasses-100.png'
import hatchingChick from '../components/assets/emojis/icons8-hatching-chick-100.png'
import helicopter from '../components/assets/emojis/icons8-helicopter-100.png'
import houseWithGarden from '../components/assets/emojis/icons8-house-with-garden-100.png'
import iceSkate from '../components/assets/emojis/icons8-ice-skate-emoji-100.png'
import lion from '../components/assets/emojis/icons8-lion-100.png'
import mobilePhone from '../components/assets/emojis/icons8-mobile-phone-100.png'
import paperClip from '../components/assets/emojis/icons8-paperclip-100.png'
import polarBear from '../components/assets/emojis/icons8-polar-bear-100.png'
import rabbitFace from '../components/assets/emojis/icons8-rabbit-face-100.png'
import rainbow from '../components/assets/emojis/icons8-rainbow-100.png'
import relievedFace from '../components/assets/emojis/icons8-relieved-face-100.png'
import robot from '../components/assets/emojis/icons8-robot-emoji-100.png'
import rocket from '../components/assets/emojis/icons8-rocket-100.png'
import serviceDog from '../components/assets/emojis/icons8-service-dog-100.png'
import shoppingBags from '../components/assets/emojis/icons8-shopping-bags-100.png'
import soccerBall from '../components/assets/emojis/icons8-soccer-ball-100.png'
import star from '../components/assets/emojis/icons8-star-100.png'
import sun from '../components/assets/emojis/icons8-sun-100.png'
import sunBehindRainCloud from '../components/assets/emojis/icons8-sun-behind-rain-cloud-100.png'
import sunflower from '../components/assets/emojis/icons8-sunflower-100.png'
import sunglasses from '../components/assets/emojis/icons8-sunglasses-100.png'
import tRex from '../components/assets/emojis/icons8-t-rex-100.png'
import teddyBear from '../components/assets/emojis/icons8-teddy-bear-100.png'
import thumbsUp from '../components/assets/emojis/icons8-thumbs-up-100.png'

export const emojis = [
  {icon: cake, value: '05'},
  {icon: automobile, value: '01'},
  {icon: backpack, value: '02'},
  {icon: beach, value: '03'},
  {icon: beatingHeart, value: '04'},
  {icon: airplane, value: '00'},
  {icon: butterfly, value: '06'},
  {icon: drum, value: '11'},
  {icon: face, value: '12'},
  {icon: fire, value: '13'},
  {icon: moon, value: '14'},
  {icon: carrot, value: '07'},
  {icon: castle, value: '08'},
  {icon: crown, value: '09'},
  {icon: doughnut, value: '10'},
  {icon: glasses, value: '15'},
  {icon: hatchingChick, value: '16'},
  {icon: helicopter, value: '17'},
  {icon: houseWithGarden, value: '18'},
  {icon: iceSkate, value: '19'},
  {icon: lion, value: '20'},
  {icon: mobilePhone, value: '21'},
  {icon: paperClip, value: '22'},
  {icon: polarBear, value: '23'},
  {icon: rabbitFace, value: '24'},
  {icon: rainbow, value: '25'},
  {icon: relievedFace, value: '26'},
  {icon: robot, value: '27'},
  {icon: rocket, value: '28'},
  {icon: serviceDog, value: '29'},
  {icon: shoppingBags, value: '30'},
  {icon: soccerBall, value: '31'},
  {icon: star, value: '32'},
  {icon: sun, value: '33'},
  {icon: sunBehindRainCloud, value: '34'},
  {icon: sunflower, value: '35'},
  {icon: sunglasses, value: '36'},
  {icon: tRex, value: '37'},
  {icon: teddyBear, value: '38'},
  {icon: thumbsUp, value: '39'},
]
