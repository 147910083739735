import React, {useEffect} from 'react'
import {Chip, Grid, Stack, MenuItem, Skeleton, Button} from '@mui/material'
import surveysApi from '../../services/api/surveys/surveysApi'
import WbButton from '../../components/common/WbButton'
import {useNavigate} from 'react-router-dom'
import paths from '../../routes/paths'
import {AUDIENCE, AUDIENCE_SELECT} from '../../utils/constants'
import CreateSurvey from '../SurveyPanel/CreateSurvey'
import AddIcon from '@mui/icons-material/Add'
import WbTextField from '../../components/common/WbTextField'
import SurveyCard from './SurveyCard'
import welbeelogo from '../../components/assets/images/welbee_logo.svg'
import Header from '../../components/layouts/header/Header'
import useCustomerData from '../../hooks/useCustomerData'
import {get} from 'lodash'

export default function Dashboard() {
  const customer = useCustomerData()

  const [surveys, setSurveys] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [createNew, setCreateNew] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [filteredSurveys, setFilteredSurveys] = React.useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (!customer) {
      navigate('/login')
    } else {
      loadSurveys()
    }
  }, [])

  const loadSurveys = () => {
    setLoading(true)
    surveysApi
      .getAll(customer.id, customer.customerType, 50)
      .then(res => {
        let fs = res.sort((a, b) => a.name.localeCompare(b.name))
        setSurveys([...fs])
        setFilteredSurveys([...fs])
      })
      .catch(err => {})
      .finally(() => setLoading(false))
  }
  const getChip = audience =>
    audience?.map(a => (
      <Chip
        style={{
          backgroundColor:
            a === 'Staff' ? '#ff5939' : a === 'Parent' ? '#45338c' : '#58b5e0',
          color: 'white',
        }}
        label={a}
      />
    ))

  const getAudience = audience => {
    switch (audience) {
      case AUDIENCE.STAFF:
        return getChip(['Staff'])
      case AUDIENCE.PARENT:
        return getChip(['Parent'])
      case AUDIENCE.STUDENT:
        return getChip(['Student'])
      case AUDIENCE.STAFF + AUDIENCE.PARENT:
        return getChip(['Staff', 'Parent'])
      case AUDIENCE.STAFF + AUDIENCE.STUDENT:
        return getChip(['Staff', 'Student'])
      case AUDIENCE.PARENT + AUDIENCE.STUDENT:
        return getChip(['Parent', 'Student'])
      case AUDIENCE.STAFF + AUDIENCE.PARENT + AUDIENCE.STUDENT:
        return getChip(['Staff', 'Parent', 'Student'])
      default:
        return [audience]
    }
  }

  useEffect(() => {
    filter()
  }, [search, status])

  const filter = () => {
    setFilteredSurveys([
      ...surveys?.filter(x => {
        return (
          (search === ''
            ? (x.name = x.name)
            : x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) &&
          filterStatus(x)
        )
      }),
    ])
  }

  const filterStatus = s => {
    if (status === '') return true
    return s.status === status
  }

  const getSelectedFilterStyle = st => {
    if (st === status) {
      return {backgroundColor: '#EDE7F6'}
    }
    return {color: '', backgroundColor: '#fff'}
  }

  return (
    <>
      <Header />
      <div className="dashboard-container">
        <div className="left-container">
          <div className="school-logo">
            <img src={welbeelogo} alt="School Logo" />
          </div>
          <div className="all-surveys">
            <ul style={{cursor: 'pointer'}}>
              <li
                style={getSelectedFilterStyle('')}
                onClick={x => setStatus('')}
              >
                All Surveys
                <span>{surveys.length}</span>
              </li>
              <li
                style={getSelectedFilterStyle('Live')}
                onClick={() => setStatus('Live')}
              >
                Live Surveys
                <span>{surveys.filter(x => x.status === 'Live').length}</span>
              </li>
              <li
                style={getSelectedFilterStyle('Draft')}
                onClick={x => setStatus('Draft')}
              >
                Draft Surveys
                <span>{surveys.filter(x => x.status === 'Draft').length}</span>
              </li>
              <li
                style={getSelectedFilterStyle('Published')}
                onClick={x => setStatus('Published')}
              >
                Published Surveys
                <span>
                  {surveys.filter(x => x.status === 'Published').length}
                </span>
              </li>
              <li
                style={getSelectedFilterStyle('Closed')}
                onClick={x => setStatus('Closed')}
              >
                Closed Surveys
                <span>{surveys.filter(x => x.status === 'Closed').length}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="right-container">
          <div className="survey-dashboard">
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h1>All Surveys</h1>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} my={3}>
                <Grid container justifyContent="space-between" rowGap={1}>
                  <Grid item xl={6} lg={6} md={9} sm={12} xs={12}>
                    <Stack direction={'row'} spacing={1}>
                      <WbButton
                        CustomButtonText={'Create new survey'}
                        onClick={() => setCreateNew(true)}
                        startIcon={<AddIcon />}
                      ></WbButton>

                      <WbTextField label="Sort" select sx={{width: '300px'}}>
                        <MenuItem value={1} selected>
                          Sort alphabetically
                        </MenuItem>
                        <MenuItem value={2}>At least</MenuItem>
                        <MenuItem value={3}>Maximum</MenuItem>
                      </WbTextField>
                    </Stack>
                  </Grid>

                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <WbTextField
                      label="Search"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    ></WbTextField>
                  </Grid>
                </Grid>
              </Grid>
              {createNew && (
                <CreateSurvey
                  onClose={() => setCreateNew(!createNew)}
                ></CreateSurvey>
              )}
            </Grid>

            <Grid container spacing={2}>
              {loading ? (
                <Grid item lg={12} md={12} sm={12} xs={12} my={5}>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Grid>
              ) : (
                filteredSurveys.map(survey => {
                  return (
                    <Grid item xxl={3} xl={4} lg={4} md={6} sm={6} xs={12}>
                      <SurveyCard
                        survey={survey}
                        loadSurveys={loadSurveys}
                        updateListOnDelete={loadSurveys}
                      ></SurveyCard>
                    </Grid>
                  )
                })
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}
