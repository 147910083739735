import {Button, IconButton} from '@mui/material'
import React from 'react'

export default function EmojiButton({icon, value, handleClick}) {
  return (
    <Button onClick={() => handleClick(value)} className="emoji-button">
      <img src={icon} alt='emoji'/>
    </Button>
  )
}
