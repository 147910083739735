import React, {useEffect, useState} from 'react'
import {AUDIENCE} from '../../../utils/constants'
import {
  Alert,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from '@mui/material'
import {Edit} from '@mui/icons-material'
import {pink} from '@mui/material/colors'
import {surveysApi} from '../../../services'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import WbSwitch from '../../../components/common/WbSwitch'
import WbButton from '../../../components/common/WbButton'
import CustomisedInvitations from './CustomisedInvitations'
import useCustomerData from '../../../hooks/useCustomerData'

export default function Invite({survey, tab, setSurvey}) {
  const customer = useCustomerData()
  const [email, setEmail] = useState(false)
  const [portal, setPortal] = useState(false)
  const [sms, setSms] = useState(false)
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  //const [editSubject, setEditSubject] = useState(false)
  //const [editMessage, setEditMessage] = useState(false)

  useEffect(() => {
    if (survey) {
      let n = null
      if (tab === AUDIENCE.STAFF) {
        n = JSON.parse(survey?.staffNotification)
      }
      if (tab === AUDIENCE.PARENT) {
        n = JSON.parse(survey?.parentNotification)
      }
      if (tab === AUDIENCE.STUDENT) {
        n = JSON.parse(survey?.pupilNotification)
        n.Email = true //ToDo need to remove this line
      }
      if (n) {
        setEmail(n.Email)
        setSms(n.Sms)
        setPortal(n.Portal)
        setSubject(n.Subject)
        setMessage(n.Message)
      }
    }
  }, [tab])
  useEffect(() => {
    if (survey) {
      let n = {
        Email: email,
        Sms: sms,
        Portal: portal,
        Subject: subject,
        Message: message,
      }
      let newSurvey = {...survey}
      if (tab === AUDIENCE.STAFF) {
        newSurvey = {...survey, staffNotification: JSON.stringify(n)}
      }
      if (tab === AUDIENCE.PARENT) {
        newSurvey = {...survey, parentNotification: JSON.stringify(n)}
      }
      if (tab === AUDIENCE.STUDENT) {
        newSurvey = {...survey, pupilNotification: JSON.stringify(n)}
      }
      setSurvey(newSurvey)
      //surveysApi.put(newSurvey).then(e => {})
    }
  }, [email, sms, portal, subject, message])

  const utiliseTemplate = template => {
    setSubject(template.subject)
    setMessage(template.message)
  }
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormControl>
          <FormControlLabel
            control={
              <WbSwitch
                checked={email}
                onChange={e => setEmail(!email)}
              ></WbSwitch>
            }
            label="Notify by Email"
          ></FormControlLabel>
        </FormControl>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12} mt={4} mb={4}>
        <Alert severity="info">
          The email invitation below will be sent to participants unless you
          choose to customise it. You can customise templates for each audience.
        </Alert>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        Email Subject: {subject}
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12} style={{textAlign: 'right'}}>
        <CustomisedInvitations
          utiliseTemplate={utiliseTemplate}
          audience={tab}
          survey={survey}
          setSurvey={setSurvey}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div
          //dangerouslySetInnerHTML={{__html: message.replace(/\n/g, '<br />')}}
          dangerouslySetInnerHTML={{
            __html: message.replace('{schoolName}', customer.name),
          }}
        ></div>
        {/* <>
          {' '}
          <p>
            <br />
            <p style={{color: ' #53B5E0', fontWeight: 900}}>
              Please click here to begin.
            </p>
          </p>
          <p>
            <br />{' '}
            <strong>
              Welbee (on behalf of {'{'}schoolName{'}'})
            </strong>
            <br />
          </p>
        </> */}
      </Grid>
    </Grid>
  )
}
