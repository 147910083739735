import React from 'react'
import {emojis} from '../../../../utils/emojis'

export default function SelectedEmoji({value}) {
  return (
    <div className="selected-emoji">
      {value && (
        <img src={emojis.find(emoji => emoji.value === value)?.icon}/>
      )}
    </div>
  )
}
