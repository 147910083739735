import React, {useEffect} from 'react'
import paths from '../../routes/paths'
import {CircularProgress} from '@mui/material'
import {surveysApi} from '../../services'
import PupilDashboard from './PupilDashboard'

export default function PupilSurveys() {
  const [loading, setLoading] = React.useState(true)
  const [pupil, setPupil] = React.useState(null)
  const [surveys, setSurveys] = React.useState([])
  useEffect(() => {
    var pupil = JSON.parse(localStorage.getItem('pupil'))
    if (!pupil) {
      window.location.href = paths.pupilDashLogin
    }
    surveysApi.getPupilSurveys(pupil.schoolId, pupil.studentId).then(res => {
      if (res.length === 1) {
        window.location.href = res[0].url
      }
      setSurveys(res)
      setLoading(false)
    })
  }, [])

  return loading ? <CircularProgress /> : <PupilDashboard surveys={surveys} />
}
